/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CredentialResponseSchemaAllOf
 */
export interface CredentialResponseSchemaAllOf {
    /**
     * id used for authentication
     * @type {string}
     * @memberof CredentialResponseSchemaAllOf
     */
    readonly client_id: string;
    /**
     * secret used for authentication
     * @type {string}
     * @memberof CredentialResponseSchemaAllOf
     */
    readonly client_secret?: string;
}

/**
 * Check if a given object implements the CredentialResponseSchemaAllOf interface.
 */
export function instanceOfCredentialResponseSchemaAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "client_id" in value;

    return isInstance;
}

export function CredentialResponseSchemaAllOfFromJSON(json: any): CredentialResponseSchemaAllOf {
    return CredentialResponseSchemaAllOfFromJSONTyped(json, false);
}

export function CredentialResponseSchemaAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): CredentialResponseSchemaAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client_id': json['client_id'],
        'client_secret': !exists(json, 'client_secret') ? undefined : json['client_secret'],
    };
}

export function CredentialResponseSchemaAllOfToJSON(value?: CredentialResponseSchemaAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

