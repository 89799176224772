/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplicationRequestSchema,
  ApplicationResponseSchema,
  GetApplications200Response,
  GetCurrentDeveloper4XXResponse,
  IdSchema,
} from '../models';
import {
    ApplicationRequestSchemaFromJSON,
    ApplicationRequestSchemaToJSON,
    ApplicationResponseSchemaFromJSON,
    ApplicationResponseSchemaToJSON,
    GetApplications200ResponseFromJSON,
    GetApplications200ResponseToJSON,
    GetCurrentDeveloper4XXResponseFromJSON,
    GetCurrentDeveloper4XXResponseToJSON,
    IdSchemaFromJSON,
    IdSchemaToJSON,
} from '../models';

export interface DeleteApplicationRequest {
    organization_id: number;
    application_id: number;
}

export interface GetApplicationRequest {
    organization_id: number;
    application_id: number;
}

export interface GetApplicationsRequest {
    organization_id: number;
}

export interface PostApplicationsRequest {
    organization_id: number;
    ApplicationRequestSchema: ApplicationRequestSchema;
}

export interface PutApplicationRequest {
    organization_id: number;
    application_id: number;
    ApplicationRequestSchema: ApplicationRequestSchema;
}

/**
 * 
 */
export class ApplicationsApi extends runtime.BaseAPI {

    /**
     * Removes an application
     */
    async deleteApplicationRaw(requestParameters: DeleteApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organization_id === null || requestParameters.organization_id === undefined) {
            throw new runtime.RequiredError('organization_id','Required parameter requestParameters.organization_id was null or undefined when calling deleteApplication.');
        }

        if (requestParameters.application_id === null || requestParameters.application_id === undefined) {
            throw new runtime.RequiredError('application_id','Required parameter requestParameters.application_id was null or undefined when calling deleteApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("UserAuth", ["profile", "openid", "email"]);
        }

        const response = await this.request({
            path: `/organizations/{organization_id}/applications/{application_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organization_id))).replace(`{${"application_id"}}`, encodeURIComponent(String(requestParameters.application_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an application
     */
    async deleteApplication(requestParameters: DeleteApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteApplicationRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves an application
     */
    async getApplicationRaw(requestParameters: GetApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationResponseSchema>> {
        if (requestParameters.organization_id === null || requestParameters.organization_id === undefined) {
            throw new runtime.RequiredError('organization_id','Required parameter requestParameters.organization_id was null or undefined when calling getApplication.');
        }

        if (requestParameters.application_id === null || requestParameters.application_id === undefined) {
            throw new runtime.RequiredError('application_id','Required parameter requestParameters.application_id was null or undefined when calling getApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("UserAuth", ["profile", "openid", "email"]);
        }

        const response = await this.request({
            path: `/organizations/{organization_id}/applications/{application_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organization_id))).replace(`{${"application_id"}}`, encodeURIComponent(String(requestParameters.application_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Retrieves an application
     */
    async getApplication(requestParameters: GetApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationResponseSchema> {
        const response = await this.getApplicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all applications for the organization
     */
    async getApplicationsRaw(requestParameters: GetApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetApplications200Response>> {
        if (requestParameters.organization_id === null || requestParameters.organization_id === undefined) {
            throw new runtime.RequiredError('organization_id','Required parameter requestParameters.organization_id was null or undefined when calling getApplications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("UserAuth", ["profile", "openid", "email"]);
        }

        const response = await this.request({
            path: `/organizations/{organization_id}/applications`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organization_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetApplications200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves all applications for the organization
     */
    async getApplications(requestParameters: GetApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetApplications200Response> {
        const response = await this.getApplicationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates application
     */
    async postApplicationsRaw(requestParameters: PostApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdSchema>> {
        if (requestParameters.organization_id === null || requestParameters.organization_id === undefined) {
            throw new runtime.RequiredError('organization_id','Required parameter requestParameters.organization_id was null or undefined when calling postApplications.');
        }

        if (requestParameters.ApplicationRequestSchema === null || requestParameters.ApplicationRequestSchema === undefined) {
            throw new runtime.RequiredError('ApplicationRequestSchema','Required parameter requestParameters.ApplicationRequestSchema was null or undefined when calling postApplications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("UserAuth", ["profile", "openid", "email"]);
        }

        const response = await this.request({
            path: `/organizations/{organization_id}/applications`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organization_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationRequestSchemaToJSON(requestParameters.ApplicationRequestSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdSchemaFromJSON(jsonValue));
    }

    /**
     * Creates application
     */
    async postApplications(requestParameters: PostApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdSchema> {
        const response = await this.postApplicationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an application
     */
    async putApplicationRaw(requestParameters: PutApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organization_id === null || requestParameters.organization_id === undefined) {
            throw new runtime.RequiredError('organization_id','Required parameter requestParameters.organization_id was null or undefined when calling putApplication.');
        }

        if (requestParameters.application_id === null || requestParameters.application_id === undefined) {
            throw new runtime.RequiredError('application_id','Required parameter requestParameters.application_id was null or undefined when calling putApplication.');
        }

        if (requestParameters.ApplicationRequestSchema === null || requestParameters.ApplicationRequestSchema === undefined) {
            throw new runtime.RequiredError('ApplicationRequestSchema','Required parameter requestParameters.ApplicationRequestSchema was null or undefined when calling putApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("UserAuth", ["profile", "openid", "email"]);
        }

        const response = await this.request({
            path: `/organizations/{organization_id}/applications/{application_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organization_id))).replace(`{${"application_id"}}`, encodeURIComponent(String(requestParameters.application_id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationRequestSchemaToJSON(requestParameters.ApplicationRequestSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates an application
     */
    async putApplication(requestParameters: PutApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putApplicationRaw(requestParameters, initOverrides);
    }

}
