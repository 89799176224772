/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminsResponseSchema
 */
export interface AdminsResponseSchema {
    /**
     * ID
     * @type {number}
     * @memberof AdminsResponseSchema
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof AdminsResponseSchema
     */
    readonly created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdminsResponseSchema
     */
    readonly updated_at?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AdminsResponseSchema
     */
    readonly deleted_at?: Date | null;
    /**
     * provider name
     * @type {string}
     * @memberof AdminsResponseSchema
     */
    provider_name: string;
    /**
     * provider user id
     * @type {string}
     * @memberof AdminsResponseSchema
     */
    provider_user_id: string;
}

/**
 * Check if a given object implements the AdminsResponseSchema interface.
 */
export function instanceOfAdminsResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "provider_name" in value;
    isInstance = isInstance && "provider_user_id" in value;

    return isInstance;
}

export function AdminsResponseSchemaFromJSON(json: any): AdminsResponseSchema {
    return AdminsResponseSchemaFromJSONTyped(json, false);
}

export function AdminsResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminsResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updated_at': !exists(json, 'updated_at') ? undefined : (json['updated_at'] === null ? null : new Date(json['updated_at'])),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (json['deleted_at'] === null ? null : new Date(json['deleted_at'])),
        'provider_name': json['provider_name'],
        'provider_user_id': json['provider_user_id'],
    };
}

export function AdminsResponseSchemaToJSON(value?: AdminsResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider_name': value.provider_name,
        'provider_user_id': value.provider_user_id,
    };
}

