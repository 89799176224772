import React, {useState} from 'react';
import {Alert, Modal, Stack} from 'react-bootstrap';
import {handleErrors, useApiClientConfiguration} from '../../Helper';
import {Configuration, EntitlementGroupRequestSchema, EntitlementsApi} from '../../sdk';
import assert from 'assert';
import {TypeColumn} from '@inovua/reactdatagrid-community/types';
import '@inovua/reactdatagrid-community/index.css'
import AdminTableEditor from './AdminTableEditor';
import AdministratorLayout, {AdministratorLoader} from './AdministratorLayout';

export async function EntitlementGroupsLoader(apiClientConfiguration: Configuration) {
	const entitlementGroupsPromise = (new EntitlementsApi(apiClientConfiguration)).getEntitlementsGroups().then(x => x.results);
	return {...await AdministratorLoader(apiClientConfiguration), entitlementGroups: await entitlementGroupsPromise};
}

export default function EntitlementGroups() : JSX.Element {
	const apiClientConfiguration = useApiClientConfiguration();
	const entitlementsApi = new EntitlementsApi(apiClientConfiguration);

	const [modalError, setModalError] = useState<string | undefined>();
	const [highlightEmptyMandatoryFields, setHighlightEmptyMandatoryFields] = useState(false);
	const [newGroup, setNewGroup] = useState<EntitlementGroupRequestSchema>(getEntitlementGroupInitialState());

	const groupColumns: TypeColumn[] = [
		{name: 'identifier', header: 'Identifier', minWidth: 20, defaultFlex: 2, editable: false},
		{name: 'name', header: 'Name', minWidth: 20, defaultFlex: 1, editable: false},
	]

	function getEntitlementGroupInitialState(): EntitlementGroupRequestSchema {
		return {identifier: '', name: ''};
	}

	async function addEntitlementsGroup() {
		await handleErrors(setModalError, async () => {
			setHighlightEmptyMandatoryFields(true);
			assert.ok(newGroup.identifier && newGroup.name, 'Please fill in all mandatory fields');
			await entitlementsApi.postEntitlementsGroups({EntitlementGroupRequestSchema: newGroup});
			setNewGroup(getEntitlementGroupInitialState());
			setHighlightEmptyMandatoryFields(false);
		});
	}

	return AdministratorLayout({
		children:
			<AdminTableEditor
				dataType="Entitlement Group"
				partialColumns={groupColumns}
				loadData={entitlementsApi.getEntitlementsGroups.bind(entitlementsApi)}
				onAdd={addEntitlementsGroup}
				onRemove={(x) => entitlementsApi.deleteEntitlementGroup({entitlement_group_id: x.id})}
				modalBody={
					<Modal.Body>
						<Stack direction="vertical" gap={4}>
							<Stack direction="vertical" gap={1}>
								<div className="input_title">Identifier (*)</div>
								<input onKeyDown={(event) => event.code === 'Space' && event.preventDefault()}
									   maxLength={64} type="text"
									   className={'input_wide' + (!newGroup.identifier && highlightEmptyMandatoryFields ? ' border-danger' : '')}
									   value={newGroup.identifier}
									   onChange={e => setNewGroup({...newGroup, identifier: e.target.value})}></input>
								<div className="input_description ">Maximum length 64 characters, no spaces</div>
							</Stack>
							<Stack direction="vertical" gap={1}>
								<div className="input_title">Display Name (*)</div>
								<input type="text"
									   className={'input_wide' + (!newGroup.name && highlightEmptyMandatoryFields ? ' border-danger' : '')}
									   value={newGroup.name}
									   onChange={e => setNewGroup({...newGroup, name: e.target.value})}></input>
							</Stack>
							{modalError ? <Alert key="danger" variant="danger">{modalError}</Alert> : <div></div>}
						</Stack>
					</Modal.Body>
				}/>
	});
}
