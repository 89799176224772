import {Organization, Provider, User} from './Provider';
import assert from 'assert';

export class GitHub extends Provider {
	constructor() {
		super('GitHub');
	}

	async getUser(userName: string): Promise<User> {
		assert.ok(userName, 'Please fill the user name in order to find it');
		const user = await this.fetchData('https://api.github.com/users/', userName);
		assert.equal(user.type, 'User', `${userName} is not a User`);

		return {
			provider_user_id: user.id.toString(),
			provider_name: this.name
		};
	}

	async getOrganization(organizationName: string): Promise<Organization> {
		assert.ok(organizationName, 'Please fill the organization name in order to find it');
		const organization = await this.fetchData('https://api.github.com/users/', organizationName); // in GitHub organizations are given in the Users api with type: Organization
		assert.equal(organization.type, 'Organization', `${organizationName} is not an Organization`);

		return {
			external_id: organization.id.toString(),
			name: organization.login,
			description: organization.bio,
			logo_url: organization.avatar_url,
			external_provider: this.name
		};
	}
}
