import {OrganizationRequestSchema} from '../sdk';

export type User = { provider_user_id: string, provider_name: string }
export type Organization = OrganizationRequestSchema;

export abstract class Provider {
	readonly name: string
	protected constructor(name: string) {
		this.name = name;
	}

	protected async fetchData(url: string, name: string) {
		const downloadUrl = new URL(encodeURIComponent(name), url);
		const query = await fetch(downloadUrl);
		return await query.json();
	}

	abstract getUser(userName: string): Promise<User>;
	abstract getOrganization(organizationName: string): Promise<Organization>;
}
