/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetCurrentDeveloper4XXResponse,
  GetDevelopers403Response,
  GetOrganizations200Response,
  IdSchema,
  OrganizationIntersectRequestSchema,
  OrganizationRequestSchema,
  OrganizationResponseSchema,
  PostIntersectOrganizations200Response,
} from '../models';
import {
    GetCurrentDeveloper4XXResponseFromJSON,
    GetCurrentDeveloper4XXResponseToJSON,
    GetDevelopers403ResponseFromJSON,
    GetDevelopers403ResponseToJSON,
    GetOrganizations200ResponseFromJSON,
    GetOrganizations200ResponseToJSON,
    IdSchemaFromJSON,
    IdSchemaToJSON,
    OrganizationIntersectRequestSchemaFromJSON,
    OrganizationIntersectRequestSchemaToJSON,
    OrganizationRequestSchemaFromJSON,
    OrganizationRequestSchemaToJSON,
    OrganizationResponseSchemaFromJSON,
    OrganizationResponseSchemaToJSON,
    PostIntersectOrganizations200ResponseFromJSON,
    PostIntersectOrganizations200ResponseToJSON,
} from '../models';

export interface DeleteOrganizationRequest {
    organization_id: number;
}

export interface GetOrganizationRequest {
    organization_id: number;
}

export interface PostIntersectOrganizationsRequest {
    OrganizationIntersectRequestSchema: OrganizationIntersectRequestSchema;
}

export interface PostOrganizationsRequest {
    OrganizationRequestSchema: OrganizationRequestSchema;
}

export interface PutOrganizationRequest {
    organization_id: number;
    OrganizationRequestSchema: OrganizationRequestSchema;
}

/**
 * 
 */
export class OrganizationsApi extends runtime.BaseAPI {

    /**
     * Removes an organization
     */
    async deleteOrganizationRaw(requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organization_id === null || requestParameters.organization_id === undefined) {
            throw new runtime.RequiredError('organization_id','Required parameter requestParameters.organization_id was null or undefined when calling deleteOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/organizations/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organization_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an organization
     */
    async deleteOrganization(requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOrganizationRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves an organization
     */
    async getOrganizationRaw(requestParameters: GetOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationResponseSchema>> {
        if (requestParameters.organization_id === null || requestParameters.organization_id === undefined) {
            throw new runtime.RequiredError('organization_id','Required parameter requestParameters.organization_id was null or undefined when calling getOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/organizations/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organization_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Retrieves an organization
     */
    async getOrganization(requestParameters: GetOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationResponseSchema> {
        const response = await this.getOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all accessible organizations for the current developer
     */
    async getOrganizationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrganizations200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("UserAuth", ["profile", "openid", "email"]);
        }

        const response = await this.request({
            path: `/organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrganizations200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves all accessible organizations for the current developer
     */
    async getOrganizations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrganizations200Response> {
        const response = await this.getOrganizationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Intersect organizations
     */
    async postIntersectOrganizationsRaw(requestParameters: PostIntersectOrganizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostIntersectOrganizations200Response>> {
        if (requestParameters.OrganizationIntersectRequestSchema === null || requestParameters.OrganizationIntersectRequestSchema === undefined) {
            throw new runtime.RequiredError('OrganizationIntersectRequestSchema','Required parameter requestParameters.OrganizationIntersectRequestSchema was null or undefined when calling postIntersectOrganizations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-amz-security-token"] = this.configuration.apiKey("x-amz-security-token"); // IAMAuth authentication
        }

        const response = await this.request({
            path: `/organizations/intersect`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationIntersectRequestSchemaToJSON(requestParameters.OrganizationIntersectRequestSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostIntersectOrganizations200ResponseFromJSON(jsonValue));
    }

    /**
     * Intersect organizations
     */
    async postIntersectOrganizations(requestParameters: PostIntersectOrganizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostIntersectOrganizations200Response> {
        const response = await this.postIntersectOrganizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates organization
     */
    async postOrganizationsRaw(requestParameters: PostOrganizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdSchema>> {
        if (requestParameters.OrganizationRequestSchema === null || requestParameters.OrganizationRequestSchema === undefined) {
            throw new runtime.RequiredError('OrganizationRequestSchema','Required parameter requestParameters.OrganizationRequestSchema was null or undefined when calling postOrganizations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationRequestSchemaToJSON(requestParameters.OrganizationRequestSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdSchemaFromJSON(jsonValue));
    }

    /**
     * Creates organization
     */
    async postOrganizations(requestParameters: PostOrganizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdSchema> {
        const response = await this.postOrganizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an organization
     */
    async putOrganizationRaw(requestParameters: PutOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organization_id === null || requestParameters.organization_id === undefined) {
            throw new runtime.RequiredError('organization_id','Required parameter requestParameters.organization_id was null or undefined when calling putOrganization.');
        }

        if (requestParameters.OrganizationRequestSchema === null || requestParameters.OrganizationRequestSchema === undefined) {
            throw new runtime.RequiredError('OrganizationRequestSchema','Required parameter requestParameters.OrganizationRequestSchema was null or undefined when calling putOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/organizations/{organization_id}`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organization_id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationRequestSchemaToJSON(requestParameters.OrganizationRequestSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates an organization
     */
    async putOrganization(requestParameters: PutOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putOrganizationRaw(requestParameters, initOverrides);
    }

}
