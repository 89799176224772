import React, {useState} from 'react';
import {DeveloperRequestSchema, DeveloperResponseSchema, DevelopersApi} from '../../sdk';
import {handleErrors, useApiClientConfiguration} from '../../Helper';
import {TypeColumn} from '@inovua/reactdatagrid-community/types';
import NumericEditor from '@inovua/reactdatagrid-community/NumberEditor';
import BoolEditor from '@inovua/reactdatagrid-community/BoolEditor';
import AdminTableEditor from './AdminTableEditor';
import AdministratorLayout from './AdministratorLayout';
import assert from 'assert';
import {Alert, Modal, Stack} from 'react-bootstrap';

export default function Developers() : JSX.Element {
	const apiClientConfiguration = useApiClientConfiguration();
	const developersApi = new DevelopersApi(apiClientConfiguration);

	const [modalError, setModalError] = useState<string | undefined>();
	const [newDeveloper, setNewDeveloper] = useState<DeveloperRequestSchema>(getDeveloperInitialState());
	const [highlightEmptyMandatoryFields, setHighlightEmptyMandatoryFields] = useState(false);

	const columns: TypeColumn[] = [
		{name: 'user_id', header: 'User ID', minWidth: 20, defaultFlex: 2, editable: false},
		{name: 'name', header: 'Name', minWidth: 20, defaultFlex: 1},
		{name: 'email', header: 'Email', minWidth: 20, defaultFlex: 1},
		{name: 'personal_access', header: 'Personal Access', type: 'boolean', render: (x: { value: boolean }) => x.value ? 'yes' : 'no', renderEditor: BoolEditor, minWidth: 20},
		{name: 'system_admin', header: 'System Admin', type: 'boolean', render: (x: { value: boolean }) => x.value ? 'yes' : 'no', renderEditor: BoolEditor, minWidth: 20},
		{name: 'last_used_organization', header: 'Last Organization', type: 'number', renderEditor: NumericEditor, minWidth: 20, defaultFlex: 0.5}
	]

	function getDeveloperInitialState(): DeveloperRequestSchema {
		return {name: ''};
	}

	async function removeDeveloper(developer: DeveloperResponseSchema) {
		await developersApi.deleteDeveloper({developer_id: developer.id});
	}

	async function editDeveloper(developer: DeveloperResponseSchema) {
		await developersApi.putDeveloper({
			developer_id: developer.id,
			DeveloperRequestSchema: developer
		});
	}

	async function addDeveloper(availableIdentities: Array<DeveloperResponseSchema>) {
		await handleErrors(setModalError, async () => {
			setHighlightEmptyMandatoryFields(true);
			assert.ok(newDeveloper.name.trim(),'Developer name is missing or empty');
			await developersApi.postDevelopers({DeveloperRequestSchema: newDeveloper});
			setNewDeveloper(getDeveloperInitialState());
			setHighlightEmptyMandatoryFields(false);
		});
	}

	return AdministratorLayout({
		children:
			<AdminTableEditor
				dataType="Developer"
				partialColumns={columns}
				loadData={developersApi.getDevelopers.bind(developersApi)}
				onRemove={removeDeveloper}
				onEdit={editDeveloper}
				onAdd={addDeveloper}
				modalBody={
					<Modal.Body>
						<Stack direction="vertical" gap={4}>
							<Stack direction="vertical" gap={1}>
								<div className="input_title">Name (*)</div>
								<input maxLength={64} type="text"
									   className={'input_wide' + (!newDeveloper.name && highlightEmptyMandatoryFields ? ' border-danger' : '')}
									   value={newDeveloper.name}
									   onChange={e => setNewDeveloper({
										...newDeveloper,
										name: e.target.value
									})}></input>
								<div className="input_description ">Maximum length 64 characters.</div>
							</Stack>
							<Stack direction="vertical" gap={1}>
								<div className="input_title">Email</div>
								<input type="text"
									   className='input_wide'
									   value={newDeveloper.email?.toString()}
									   onChange={e => setNewDeveloper({
										...newDeveloper,
										email: e.target.value
									})}></input>
							</Stack>

							<Stack direction="horizontal" gap={5}>
								<Stack direction="horizontal" gap={2}>
									<div className="input_title">Personal Access</div>
									<input type="checkbox"
										   checked={newDeveloper.personal_access}
										   onChange={e => setNewDeveloper({
											   ...newDeveloper,
											   personal_access: e.target.checked
										   })}></input>
								</Stack>
								<Stack direction="horizontal" gap={2}>
									<div className="input_title">System Admin</div>
									<input type="checkbox"
										   checked={newDeveloper.system_admin}
										   onChange={e => setNewDeveloper({
											   ...newDeveloper,
											   system_admin: e.target.checked
										   })}></input>
								</Stack>
							</Stack>

							{modalError ? <Alert key="danger" variant="danger">{modalError}</Alert> : <div></div>}
						</Stack>
					</Modal.Body>
				}
			/>
	});
}
