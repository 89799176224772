/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DeveloperResponseSchema,
  GetCurrentDeveloper4XXResponse,
  LastUsedOrganizationSchema,
} from '../models';
import {
    DeveloperResponseSchemaFromJSON,
    DeveloperResponseSchemaToJSON,
    GetCurrentDeveloper4XXResponseFromJSON,
    GetCurrentDeveloper4XXResponseToJSON,
    LastUsedOrganizationSchemaFromJSON,
    LastUsedOrganizationSchemaToJSON,
} from '../models';

export interface PutCurrentDeveloperLastUsedOrganizationRequest {
    LastUsedOrganizationSchema?: LastUsedOrganizationSchema;
}

/**
 * 
 */
export class DeveloperApi extends runtime.BaseAPI {

    /**
     * Retrieves current developer entry
     */
    async getCurrentDeveloperRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeveloperResponseSchema>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("UserAuth", ["profile", "openid", "email"]);
        }

        const response = await this.request({
            path: `/developer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeveloperResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Retrieves current developer entry
     */
    async getCurrentDeveloper(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeveloperResponseSchema> {
        const response = await this.getCurrentDeveloperRaw(initOverrides);
        return await response.value();
    }

    /**
     * Synchronizes current developer entry from bearer token
     */
    async putCurrentDeveloperRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeveloperResponseSchema>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("UserAuth", ["profile", "openid", "email"]);
        }

        const response = await this.request({
            path: `/developer`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeveloperResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Synchronizes current developer entry from bearer token
     */
    async putCurrentDeveloper(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeveloperResponseSchema> {
        const response = await this.putCurrentDeveloperRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates last-used-organization for the current developer entry from bearer token
     */
    async putCurrentDeveloperLastUsedOrganizationRaw(requestParameters: PutCurrentDeveloperLastUsedOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("UserAuth", ["profile", "openid", "email"]);
        }

        const response = await this.request({
            path: `/developer/last-used-organization`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LastUsedOrganizationSchemaToJSON(requestParameters.LastUsedOrganizationSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates last-used-organization for the current developer entry from bearer token
     */
    async putCurrentDeveloperLastUsedOrganization(requestParameters: PutCurrentDeveloperLastUsedOrganizationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putCurrentDeveloperLastUsedOrganizationRaw(requestParameters, initOverrides);
    }

}
