/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentityRequestSchema
 */
export interface IdentityRequestSchema {
    /**
     * Identity provider name
     * @type {string}
     * @memberof IdentityRequestSchema
     */
    provider: string;
    /**
     * Identity provider user id
     * @type {string}
     * @memberof IdentityRequestSchema
     */
    user_id: string;
    /**
     * Developer to whom the identity belongs
     * @type {number}
     * @memberof IdentityRequestSchema
     */
    developer_id: number;
}

/**
 * Check if a given object implements the IdentityRequestSchema interface.
 */
export function instanceOfIdentityRequestSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "provider" in value;
    isInstance = isInstance && "user_id" in value;
    isInstance = isInstance && "developer_id" in value;

    return isInstance;
}

export function IdentityRequestSchemaFromJSON(json: any): IdentityRequestSchema {
    return IdentityRequestSchemaFromJSONTyped(json, false);
}

export function IdentityRequestSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityRequestSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': json['provider'],
        'user_id': json['user_id'],
        'developer_id': json['developer_id'],
    };
}

export function IdentityRequestSchemaToJSON(value?: IdentityRequestSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': value.provider,
        'user_id': value.user_id,
        'developer_id': value.developer_id,
    };
}

