/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrganizationResponseSchema } from './OrganizationResponseSchema';
import {
    OrganizationResponseSchemaFromJSON,
    OrganizationResponseSchemaFromJSONTyped,
    OrganizationResponseSchemaToJSON,
} from './OrganizationResponseSchema';

/**
 * 
 * @export
 * @interface GetOrganizations200Response
 */
export interface GetOrganizations200Response {
    /**
     * 
     * @type {Array<OrganizationResponseSchema>}
     * @memberof GetOrganizations200Response
     */
    results: Array<OrganizationResponseSchema>;
}

/**
 * Check if a given object implements the GetOrganizations200Response interface.
 */
export function instanceOfGetOrganizations200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function GetOrganizations200ResponseFromJSON(json: any): GetOrganizations200Response {
    return GetOrganizations200ResponseFromJSONTyped(json, false);
}

export function GetOrganizations200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrganizations200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(OrganizationResponseSchemaFromJSON)),
    };
}

export function GetOrganizations200ResponseToJSON(value?: GetOrganizations200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(OrganizationResponseSchemaToJSON)),
    };
}

