/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConfigEntitlementsRequestSchemaInner,
  GetCurrentDeveloper4XXResponse,
} from '../models';
import {
    ConfigEntitlementsRequestSchemaInnerFromJSON,
    ConfigEntitlementsRequestSchemaInnerToJSON,
    GetCurrentDeveloper4XXResponseFromJSON,
    GetCurrentDeveloper4XXResponseToJSON,
} from '../models';

export interface PostConfigEntitlementsRequest {
    ConfigEntitlementsRequestSchemaInner: Array<ConfigEntitlementsRequestSchemaInner>;
}

/**
 * 
 */
export class ConfigApi extends runtime.BaseAPI {

    /**
     * Config the entitlements
     */
    async postConfigEntitlementsRaw(requestParameters: PostConfigEntitlementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ConfigEntitlementsRequestSchemaInner === null || requestParameters.ConfigEntitlementsRequestSchemaInner === undefined) {
            throw new runtime.RequiredError('ConfigEntitlementsRequestSchemaInner','Required parameter requestParameters.ConfigEntitlementsRequestSchemaInner was null or undefined when calling postConfigEntitlements.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-amz-security-token"] = this.configuration.apiKey("x-amz-security-token"); // IAMAuth authentication
        }

        const response = await this.request({
            path: `/config/entitlements`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ConfigEntitlementsRequestSchemaInner.map(ConfigEntitlementsRequestSchemaInnerToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Config the entitlements
     */
    async postConfigEntitlements(requestParameters: PostConfigEntitlementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postConfigEntitlementsRaw(requestParameters, initOverrides);
    }

}
