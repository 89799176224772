/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IdentityRequestSchema } from './IdentityRequestSchema';
import {
    IdentityRequestSchemaFromJSON,
    IdentityRequestSchemaFromJSONTyped,
    IdentityRequestSchemaToJSON,
} from './IdentityRequestSchema';

/**
 * 
 * @export
 * @interface DeveloperResponseSchemaAllOf
 */
export interface DeveloperResponseSchemaAllOf {
    /**
     * list of identities that the developer already used to login
     * @type {Array<IdentityRequestSchema>}
     * @memberof DeveloperResponseSchemaAllOf
     */
    identities?: Array<IdentityRequestSchema>;
}

/**
 * Check if a given object implements the DeveloperResponseSchemaAllOf interface.
 */
export function instanceOfDeveloperResponseSchemaAllOf(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeveloperResponseSchemaAllOfFromJSON(json: any): DeveloperResponseSchemaAllOf {
    return DeveloperResponseSchemaAllOfFromJSONTyped(json, false);
}

export function DeveloperResponseSchemaAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeveloperResponseSchemaAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identities': !exists(json, 'identities') ? undefined : ((json['identities'] as Array<any>).map(IdentityRequestSchemaFromJSON)),
    };
}

export function DeveloperResponseSchemaAllOfToJSON(value?: DeveloperResponseSchemaAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identities': value.identities === undefined ? undefined : ((value.identities as Array<any>).map(IdentityRequestSchemaToJSON)),
    };
}

