import React, {useState} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {handleErrors, useApiClientConfiguration, useApplicationId, useOrganizationId} from '../Helper';
import {
	ApplicationRequestSchema,
	ApplicationRequestSchemaEnvTypeEnum,
	ApplicationResponseSchema,
	ApplicationsApi
} from '../sdk';
import {Alert, Button, ButtonGroup, Col, Dropdown, Row, Stack} from 'react-bootstrap';
import assert from 'assert';
import {ApplicationLoader} from './Application';
import OrganizationLayout from './OrganizationLayout';

export default function AddOrUpdateApplication() : JSX.Element {
	const appId= useApplicationId();
	const orgId = useOrganizationId();
	const data = useLoaderData() as Awaited<ReturnType<typeof ApplicationLoader>>;
	const navigate = useNavigate();
	const apiClientConfiguration = useApiClientConfiguration();
	const [error, setError] = useState<string | undefined>();

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition  -- screen can be called with no application in case of adding new
	const [application, setApplication] = useState<ApplicationRequestSchema>(data.application || getApplicationsInitialState());
	const [highlightEmptyMandatoryFields, setHighlightEmptyMandatoryFields] = useState(false);

	const applicationsApi = new ApplicationsApi(apiClientConfiguration);

	function getApplicationsInitialState() {
		return {name: '', description: null, env_type: ApplicationRequestSchemaEnvTypeEnum.TEST};
	}

	function goBack() {
		navigate(-1);
	}

	async function handleSave() {
		await handleErrors(setError, async () => {
			setHighlightEmptyMandatoryFields(true);
			assert.ok(orgId, 'Organization needs to be selected');
			assert.ok(application.name.trim(),'Application name is missing or empty');
			appId
				? await applicationsApi.putApplication({organization_id: orgId, application_id: appId, ApplicationRequestSchema: application})
				: await applicationsApi.postApplications({organization_id: orgId, ApplicationRequestSchema: {name: application.name.trim(), description: application.description?.trim(), env_type: application.env_type}});
			goBack();
		});
	}
	async function disableApp() {
		if(!appId || !orgId) return;
		await applicationsApi.deleteApplication({organization_id: orgId, application_id: appId});
		await goBack();
	}

	return OrganizationLayout({
		children:
			<div>
				<div className="pages_title">{appId ? application.name : 'Applications'}</div>
				<div className="card_container mb-4">
					<div className="card_title">{appId ? 'Edit App Details' : 'Add Application'}</div>
					<div className="mt-4">
						<Stack direction="vertical" gap={5}>
							<Row className="mt-4">
								<Col>
									<Stack direction="vertical" gap={1}>
										<div className="input_title">App Name (*)</div>
										<input maxLength={64} type="text"
											   className={'input_wide' + (!application.name.trim() && highlightEmptyMandatoryFields ? ' border-danger' : '')}
											   value={application.name} onChange={e => setApplication({
												...application,
												name: e.target.value
											})}></input>
										<div className="input_description ">Maximum length 64 characters</div>
									</Stack>
								</Col>
								<Col>
									<Stack direction="vertical" gap={1}>
										<div className="input_title">App Type (*)</div>
										<Dropdown as={ButtonGroup} className={'dropdown_wide'}
												  onSelect={e => setApplication({
													  ...application,
													  env_type: e as ApplicationRequestSchemaEnvTypeEnum
												  })}>
											<Dropdown.Toggle
												className="dropdown_wide_toggle">{ApplicationRequestSchemaEnvTypeEnum[application.env_type]}</Dropdown.Toggle>
											<Dropdown.Menu>
												{Object.keys(ApplicationRequestSchemaEnvTypeEnum).map((env) =>
													<Dropdown.Item eventKey={env}
																   key={env}>{ApplicationRequestSchemaEnvTypeEnum[env as ApplicationRequestSchemaEnvTypeEnum]}</Dropdown.Item>
												)}
											</Dropdown.Menu>
										</Dropdown>
									</Stack>
								</Col>
							</Row>
							<Stack direction="vertical" gap={1}>
								<div className="input_title">Description</div>
								<textarea maxLength={200} className="textarea_wide"
										  value={application.description?.toString()} onChange={e => setApplication({
										...application,
										description: e.target.value
									})}></textarea>
								<div className="input_description">Maximum length 200 characters</div>
							</Stack>
							{error ? <Alert key="danger" variant="danger">{error}</Alert> : <div></div>}
							<Stack direction="horizontal" gap={3}>
								{appId
									? <Button className="content_danger_button"
											  disabled={Boolean((application as ApplicationResponseSchema).deleted_at)}
											  onClick={disableApp}>Delete Application</Button>
									: <div></div>
								}
								<Button className="content_light_button ms-auto" onClick={goBack}>Cancel</Button>
								<Button className="content_dark_button" onClick={handleSave}>Save</Button>
							</Stack>
						</Stack>
					</div>
				</div>
			</div>
	});
}
