import {GitHub} from './GitHub';
import {Provider} from './Provider';

export interface Providers {
	[key: string]: Provider;
}

export const providers: Providers = Object.fromEntries([
	new GitHub()
].map(provider => [provider.name, provider]))
