/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminsRequestSchema
 */
export interface AdminsRequestSchema {
    /**
     * provider name
     * @type {string}
     * @memberof AdminsRequestSchema
     */
    provider_name: string;
    /**
     * provider user id
     * @type {string}
     * @memberof AdminsRequestSchema
     */
    provider_user_id: string;
}

/**
 * Check if a given object implements the AdminsRequestSchema interface.
 */
export function instanceOfAdminsRequestSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "provider_name" in value;
    isInstance = isInstance && "provider_user_id" in value;

    return isInstance;
}

export function AdminsRequestSchemaFromJSON(json: any): AdminsRequestSchema {
    return AdminsRequestSchemaFromJSONTyped(json, false);
}

export function AdminsRequestSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminsRequestSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider_name': json['provider_name'],
        'provider_user_id': json['provider_user_id'],
    };
}

export function AdminsRequestSchemaToJSON(value?: AdminsRequestSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider_name': value.provider_name,
        'provider_user_id': value.provider_user_id,
    };
}

