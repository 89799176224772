/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationResponseSchema } from './ApplicationResponseSchema';
import {
    ApplicationResponseSchemaFromJSON,
    ApplicationResponseSchemaFromJSONTyped,
    ApplicationResponseSchemaToJSON,
} from './ApplicationResponseSchema';

/**
 * 
 * @export
 * @interface GetApplications200Response
 */
export interface GetApplications200Response {
    /**
     * 
     * @type {Array<ApplicationResponseSchema>}
     * @memberof GetApplications200Response
     */
    results: Array<ApplicationResponseSchema>;
}

/**
 * Check if a given object implements the GetApplications200Response interface.
 */
export function instanceOfGetApplications200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function GetApplications200ResponseFromJSON(json: any): GetApplications200Response {
    return GetApplications200ResponseFromJSONTyped(json, false);
}

export function GetApplications200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetApplications200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(ApplicationResponseSchemaFromJSON)),
    };
}

export function GetApplications200ResponseToJSON(value?: GetApplications200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(ApplicationResponseSchemaToJSON)),
    };
}

