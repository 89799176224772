/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntitlementGroupRequestSchema } from './EntitlementGroupRequestSchema';
import {
    EntitlementGroupRequestSchemaFromJSON,
    EntitlementGroupRequestSchemaFromJSONTyped,
    EntitlementGroupRequestSchemaToJSON,
} from './EntitlementGroupRequestSchema';
import type { EntitlementRequestBaseSchema } from './EntitlementRequestBaseSchema';
import {
    EntitlementRequestBaseSchemaFromJSON,
    EntitlementRequestBaseSchemaFromJSONTyped,
    EntitlementRequestBaseSchemaToJSON,
} from './EntitlementRequestBaseSchema';

/**
 * 
 * @export
 * @interface ConfigEntitlementsRequestSchemaInner
 */
export interface ConfigEntitlementsRequestSchemaInner {
    /**
     * 
     * @type {EntitlementGroupRequestSchema}
     * @memberof ConfigEntitlementsRequestSchemaInner
     */
    group: EntitlementGroupRequestSchema;
    /**
     * 
     * @type {Array<EntitlementRequestBaseSchema>}
     * @memberof ConfigEntitlementsRequestSchemaInner
     */
    entitlements: Array<EntitlementRequestBaseSchema>;
}

/**
 * Check if a given object implements the ConfigEntitlementsRequestSchemaInner interface.
 */
export function instanceOfConfigEntitlementsRequestSchemaInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "group" in value;
    isInstance = isInstance && "entitlements" in value;

    return isInstance;
}

export function ConfigEntitlementsRequestSchemaInnerFromJSON(json: any): ConfigEntitlementsRequestSchemaInner {
    return ConfigEntitlementsRequestSchemaInnerFromJSONTyped(json, false);
}

export function ConfigEntitlementsRequestSchemaInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigEntitlementsRequestSchemaInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'group': EntitlementGroupRequestSchemaFromJSON(json['group']),
        'entitlements': ((json['entitlements'] as Array<any>).map(EntitlementRequestBaseSchemaFromJSON)),
    };
}

export function ConfigEntitlementsRequestSchemaInnerToJSON(value?: ConfigEntitlementsRequestSchemaInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group': EntitlementGroupRequestSchemaToJSON(value.group),
        'entitlements': ((value.entitlements as Array<any>).map(EntitlementRequestBaseSchemaToJSON)),
    };
}

