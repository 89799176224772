import '@inovua/reactdatagrid-community/index.css'
import React, {useEffect, useMemo, useState} from 'react';
import {Alert, Button, Form, Modal, Stack} from 'react-bootstrap';
import ReactDataGrid from '@inovua/reactdatagrid-community'
import {TypeColumn, TypeEditInfo} from '@inovua/reactdatagrid-community/types';
import {TypeOnSelectionChangeArg} from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import Pluralize from 'pluralize';

export default function AdminTableEditor(props: {
	dataType: string,
	tableTitle?: string,
	partialColumns: TypeColumn[],
	gridStyle?: { [key: string]: string | number; },
	modalBody?: JSX.Element,
	loadData: () => Promise<{results: Array<any>}>,
	onAdd?: ((data: Array<any>) => Promise<void>),
	onEdit?: ((data: any) => Promise<void>),
	onRemove?: ((data: any) => Promise<void>),
	onSelectionChange?: (config: TypeOnSelectionChangeArg) => void,
}) {
	const [rows, setRows] = useState<Array<any>>([]);
	const [displayRows, setDisplayRows] = useState<Array<any>>([]);
	const [loading, setLoading] = useState(true);
	const [showAdd, setShowAdd] = useState(false);
	const [showDeleted, setShowDeleted] = useState(false);
	const [error, setError] = useState<string | undefined>();

	function renderDate(x: { value: any }) {
		return x.value?.toLocaleString();
	}
	const columns: TypeColumn[] = [
		{name: 'id', header: 'ID', minWidth: 20, defaultFlex: 0.3, defaultVisible: false, editable: false, type: 'number'},
		...props.partialColumns,
		{name: 'created_at', header: 'Created',  minWidth: 20, defaultFlex: 1, editable: false, render: renderDate},
		{name: 'updated_at', header: 'Updated', minWidth: 20, defaultFlex: 1, editable: false, render: renderDate},
		{name: 'deleted_at', header: 'Deleted', minWidth: 20, defaultFlex: 1, editable: false, visible: showDeleted, showInContextMenu: showDeleted, render: renderDate},
	]
	props.onRemove && columns.push({name: 'buttons', header:'', width: 50, textAlign: 'center' as const, showInContextMenu: false, draggable: false, filterable: false, sortable: false, cellSelectable: false, editable: false, render: ({data}) =>
		<button type="button" disabled={!!data.deleted_at} className="btn btn-primary btn-sm pt-2" onClick={() => callRemove(data)}> <i className="bi-trash3-fill"></i></button>
	});

	const dataType = props.dataType;
	const loadDataMethod = props.loadData;
	async function loadData() {
		try {
			setLoading(true);
			const data = (await loadDataMethod()).results;
			setRows(data);
		} catch (e: any) {
			setError(e.message);
		} finally {
			setLoading(false);
		}
	}
	const loadDataMemo = useMemo(loadData, [loadDataMethod]);
	useEffect(() => {
		loadDataMemo.catch(e => {setError(`Error loading ${dataType} data - ${e}`);});
		setDisplayRows(showDeleted ? rows : rows.filter((x: { deleted_at: any; }) => !x.deleted_at));
	}, [dataType, loadDataMemo, rows, showDeleted]);

	async function callRemove(data: any) {
		try {
			if(!props.onRemove) return;
			await props.onRemove(data);
			await loadData()
		} catch (e: any) {
			setError(e.message || e.title);
			throw e;
		}
	}

	async function callSave() {
		if(!props.onAdd) return;
		await props.onAdd(rows);
		setShowAdd(false);
		await loadData();
	}

	async function onEditComplete(typeEditInfo: TypeEditInfo) {
		try {
			if(props.onEdit) {
				const row = rows.find(x => x.id === (displayRows[typeEditInfo.rowIndex]).id);
				row[typeEditInfo.columnId] = typeEditInfo.value;
				await props.onEdit(row);
			}
		} catch (e: any) {
			setError(e.message);
			throw e;
		}
	}

	return (
		<div>
			<div className="pages_title">{Pluralize(props.dataType)}</div>
			{props.modalBody &&
				<Modal show={showAdd} size="lg" centered>
					<Modal.Header>
						<Modal.Title>Add {props.dataType}</Modal.Title>
					</Modal.Header>
					{props.modalBody}
					<Modal.Footer>
						<Button className="content_light_button ms-auto" onClick={() => setShowAdd(false)}>Cancel</Button>
						{props.onAdd && <Button className="content_dark_button" onClick={callSave}>Save</Button>}
					</Modal.Footer>
				</Modal>}
			{error && <Alert variant="danger">{error}</Alert>}
			<Stack direction="vertical" gap={4}>
				<Stack direction="horizontal" gap={3}>
					<h2>{props.tableTitle}</h2>
					<Form.Check className="ms-auto" label="show deleted" checked={showDeleted} onChange={e => setShowDeleted(e.target.checked)}></Form.Check>
					{props.onAdd && <Button className="content_light_button" disabled={showAdd} onClick={() => setShowAdd(true)}>+ Add</Button>}
				</Stack>
				<ReactDataGrid
					idProperty="id"
					columns={columns}
					onEditComplete={onEditComplete}
					dataSource={displayRows}
					style={props.gridStyle || {minHeight: 550}}
					allowUnsort={false}
					editable={true}
					loading={loading}
					enableSelection={!!props.onSelectionChange}
					onSelectionChange={props.onSelectionChange}
				/>
			</Stack>
		</div>
	);
}
