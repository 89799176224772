/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetCurrentDeveloper4XXResponse,
  GetDevelopers403Response,
  GetIdentities200Response,
  IdSchema,
  IdentityRequestSchema,
  IdentityResponseSchema,
} from '../models';
import {
    GetCurrentDeveloper4XXResponseFromJSON,
    GetCurrentDeveloper4XXResponseToJSON,
    GetDevelopers403ResponseFromJSON,
    GetDevelopers403ResponseToJSON,
    GetIdentities200ResponseFromJSON,
    GetIdentities200ResponseToJSON,
    IdSchemaFromJSON,
    IdSchemaToJSON,
    IdentityRequestSchemaFromJSON,
    IdentityRequestSchemaToJSON,
    IdentityResponseSchemaFromJSON,
    IdentityResponseSchemaToJSON,
} from '../models';

export interface DeleteIdentityRequest {
    provider_name: string;
    provider_user_id: string;
}

export interface GetIdentityRequest {
    provider_name: string;
    provider_user_id: string;
}

export interface PostIdentitiesRequest {
    IdentityRequestSchema: IdentityRequestSchema;
}

/**
 * 
 */
export class IdentitiesApi extends runtime.BaseAPI {

    /**
     * Deletes an Identity
     */
    async deleteIdentityRaw(requestParameters: DeleteIdentityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.provider_name === null || requestParameters.provider_name === undefined) {
            throw new runtime.RequiredError('provider_name','Required parameter requestParameters.provider_name was null or undefined when calling deleteIdentity.');
        }

        if (requestParameters.provider_user_id === null || requestParameters.provider_user_id === undefined) {
            throw new runtime.RequiredError('provider_user_id','Required parameter requestParameters.provider_user_id was null or undefined when calling deleteIdentity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/identities/{provider_name}/{provider_user_id}`.replace(`{${"provider_name"}}`, encodeURIComponent(String(requestParameters.provider_name))).replace(`{${"provider_user_id"}}`, encodeURIComponent(String(requestParameters.provider_user_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an Identity
     */
    async deleteIdentity(requestParameters: DeleteIdentityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteIdentityRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves the identities
     */
    async getIdentitiesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetIdentities200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/identities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIdentities200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves the identities
     */
    async getIdentities(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetIdentities200Response> {
        const response = await this.getIdentitiesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Checks is an identity exists
     */
    async getIdentityRaw(requestParameters: GetIdentityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentityResponseSchema>> {
        if (requestParameters.provider_name === null || requestParameters.provider_name === undefined) {
            throw new runtime.RequiredError('provider_name','Required parameter requestParameters.provider_name was null or undefined when calling getIdentity.');
        }

        if (requestParameters.provider_user_id === null || requestParameters.provider_user_id === undefined) {
            throw new runtime.RequiredError('provider_user_id','Required parameter requestParameters.provider_user_id was null or undefined when calling getIdentity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/identities/{provider_name}/{provider_user_id}`.replace(`{${"provider_name"}}`, encodeURIComponent(String(requestParameters.provider_name))).replace(`{${"provider_user_id"}}`, encodeURIComponent(String(requestParameters.provider_user_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Checks is an identity exists
     */
    async getIdentity(requestParameters: GetIdentityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentityResponseSchema> {
        const response = await this.getIdentityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a new identity
     */
    async postIdentitiesRaw(requestParameters: PostIdentitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdSchema>> {
        if (requestParameters.IdentityRequestSchema === null || requestParameters.IdentityRequestSchema === undefined) {
            throw new runtime.RequiredError('IdentityRequestSchema','Required parameter requestParameters.IdentityRequestSchema was null or undefined when calling postIdentities.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/identities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdentityRequestSchemaToJSON(requestParameters.IdentityRequestSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdSchemaFromJSON(jsonValue));
    }

    /**
     * Adds a new identity
     */
    async postIdentities(requestParameters: PostIdentitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdSchema> {
        const response = await this.postIdentitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
