/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationRequestSchema
 */
export interface OrganizationRequestSchema {
    /**
     * external organization identifier
     * @type {string}
     * @memberof OrganizationRequestSchema
     */
    external_id: string;
    /**
     * external organization provider
     * @type {string}
     * @memberof OrganizationRequestSchema
     */
    external_provider: string;
    /**
     * name of the organization
     * @type {string}
     * @memberof OrganizationRequestSchema
     */
    name: string;
    /**
     * description of the organization
     * @type {string}
     * @memberof OrganizationRequestSchema
     */
    description?: string | null;
    /**
     * organization logo url
     * @type {string}
     * @memberof OrganizationRequestSchema
     */
    logo_url?: string;
}

/**
 * Check if a given object implements the OrganizationRequestSchema interface.
 */
export function instanceOfOrganizationRequestSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "external_id" in value;
    isInstance = isInstance && "external_provider" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function OrganizationRequestSchemaFromJSON(json: any): OrganizationRequestSchema {
    return OrganizationRequestSchemaFromJSONTyped(json, false);
}

export function OrganizationRequestSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationRequestSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external_id': json['external_id'],
        'external_provider': json['external_provider'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'logo_url': !exists(json, 'logo_url') ? undefined : json['logo_url'],
    };
}

export function OrganizationRequestSchemaToJSON(value?: OrganizationRequestSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.external_id,
        'external_provider': value.external_provider,
        'name': value.name,
        'description': value.description,
        'logo_url': value.logo_url,
    };
}

