import React from 'react';
import {Link, useLoaderData, useNavigate} from 'react-router-dom';
import {Badge, Card, Stack} from 'react-bootstrap';
import OrganizationLayout, {OrganizationLoader} from './OrganizationLayout';
import {ApplicationsApi, Configuration} from '../sdk';

export async function ApplicationsLoader(apiClientConfiguration: Configuration, orgId: string) {
	const applicationsPromise = (new ApplicationsApi(apiClientConfiguration)).getApplications({organization_id: +orgId}).then(x => x.results.filter(t => !t.deleted_at)).catch(() => []);
	return {...await OrganizationLoader(apiClientConfiguration), applications: await applicationsPromise};
}

export default function Applications(): JSX.Element {
	const navigate = useNavigate();
	const {applications} = useLoaderData() as Awaited<ReturnType<typeof ApplicationsLoader>>;

	return OrganizationLayout({
		children:
			<div>
				<div className="pages_title">Applications</div>
				<div className="d-flex flex-wrap">
					<Card className="card_new mx-2 my-2">
						<Card.Body className="text-center" onClick={() => navigate(`add`)} >
							<i className="card_icon bi-plus-circle-fill"></i>
							<Card.Title>Add New App</Card.Title>
						</Card.Body>
					</Card>
					{applications.map((app) => {
						return (
							<Card key={app.id} className="mx-2 my-2">
								<Card.Body role="button" onClick={() => navigate(app.id.toString())}>
									<Card.Subtitle className="mb-2">
										<Stack direction="horizontal" gap={3}>
											<div>ID: {app.id}</div>
											<Badge bg="secondary">{app.env_type}</Badge>
											<div className="flex-fill"></div>
											<Link role="button"
												  onClick={(event) => event.stopPropagation() /* prevents triggering the parent */}
												  to={`${app.id}/update`}><i className="bi bi-pencil"/></Link>
											<i className="bi bi-gear"/>
										</Stack>
									</Card.Subtitle>
									<Card.Title className="pt-3">{app.name} </Card.Title>
								</Card.Body>
							</Card>
						);
					})}
				</div>
			</div>
	});
}
