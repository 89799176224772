/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateRequestSchema
 */
export interface ValidateRequestSchema {
    /**
     * id used for authentication
     * @type {string}
     * @memberof ValidateRequestSchema
     */
    client_id: string;
    /**
     * secret used for authentication
     * @type {string}
     * @memberof ValidateRequestSchema
     */
    client_secret: string;
}

/**
 * Check if a given object implements the ValidateRequestSchema interface.
 */
export function instanceOfValidateRequestSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "client_id" in value;
    isInstance = isInstance && "client_secret" in value;

    return isInstance;
}

export function ValidateRequestSchemaFromJSON(json: any): ValidateRequestSchema {
    return ValidateRequestSchemaFromJSONTyped(json, false);
}

export function ValidateRequestSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateRequestSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client_id': json['client_id'],
        'client_secret': json['client_secret'],
    };
}

export function ValidateRequestSchemaToJSON(value?: ValidateRequestSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.client_id,
        'client_secret': value.client_secret,
    };
}

