/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CredentialRequestSchema
 */
export interface CredentialRequestSchema {
    /**
     * 
     * @type {Array<string>}
     * @memberof CredentialRequestSchema
     */
    scopes?: Array<string> | null;
}

/**
 * Check if a given object implements the CredentialRequestSchema interface.
 */
export function instanceOfCredentialRequestSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CredentialRequestSchemaFromJSON(json: any): CredentialRequestSchema {
    return CredentialRequestSchemaFromJSONTyped(json, false);
}

export function CredentialRequestSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CredentialRequestSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scopes': !exists(json, 'scopes') ? undefined : json['scopes'],
    };
}

export function CredentialRequestSchemaToJSON(value?: CredentialRequestSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scopes': value.scopes,
    };
}

