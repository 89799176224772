import React, {useState} from 'react';
import {Alert, Button, ButtonGroup, Dropdown, Modal, Stack} from 'react-bootstrap';
import {handleErrors, useApiClientConfiguration} from '../../Helper';
import {providers} from '../../providers'
import {Configuration, DevelopersApi, IdentitiesApi, IdentityRequestSchema, IdentityResponseSchema} from '../../sdk';
import assert from 'assert';
import {TypeColumn} from '@inovua/reactdatagrid-community/types';
import AdminTableEditor from './AdminTableEditor';
import {useLoaderData} from 'react-router-dom';
import AdministratorLayout, {AdministratorLoader} from './AdministratorLayout';

export async function IdentitiesLoader(apiClientConfiguration: Configuration) {
	const developersPromise = (new DevelopersApi(apiClientConfiguration)).getDevelopers().then(x => x.results);
	return {...await AdministratorLoader(apiClientConfiguration), developers: await developersPromise};
}

export default function Identities() : JSX.Element {
	const apiClientConfiguration = useApiClientConfiguration();
	const identitiesApi = new IdentitiesApi(apiClientConfiguration);

	const [modalError, setModalError] = useState<string | undefined>();
	const [newIdentity, setNewIdentity] = useState<IdentityRequestSchema>(getIdentityInitialState());
	const [highlightEmptyMandatoryFields, setHighlightEmptyMandatoryFields] = useState(false);
	const [findUsername, setFindUsername] = useState('');
	const {developers} = useLoaderData() as Awaited<ReturnType<typeof IdentitiesLoader>>;

	const columns: TypeColumn[] = [
		{name: 'provider', header: 'Provider', minWidth: 20, defaultFlex: 1, editable: false},
		{name: 'user_id', header: 'Provider User ID', minWidth: 20, defaultFlex: 1, editable: false},
		{name: 'developer_id', header: 'Developer', minWidth: 20, defaultFlex: 1, editable: false, render: (x: { value: any }) => getDeveloperNameById(x.value)},
	]

	function getDeveloperNameById(id: any) : string {
		return (id && developers.find(y => y.id === id)?.name) || '-';
	}

	function getIdentityInitialState() : IdentityRequestSchema {
		return {provider: Object.keys(providers)[0], user_id: '', developer_id: -1};
	}

	async function removeIdentity(identity: IdentityResponseSchema) {
		await identitiesApi.deleteIdentity({provider_name: identity.provider, provider_user_id: identity.user_id});
	}

	async function addIdentity(currentIdentities: Array<IdentityResponseSchema>) {
		await handleErrors(setModalError, async () => {
			setHighlightEmptyMandatoryFields(true);
			assert.ok(newIdentity.provider && newIdentity.user_id && newIdentity.developer_id, 'Please fill in all mandatory fields');
			assert.ok(!currentIdentities.some(d => !d.deleted_at && d.user_id === newIdentity.user_id && d.provider === newIdentity.provider), 'Identity with provider name and provider id already exists');
			await identitiesApi.postIdentities({IdentityRequestSchema: newIdentity});
			setNewIdentity(getIdentityInitialState());
			setHighlightEmptyMandatoryFields(false);
			setFindUsername('');
		});
	}

	async function findAndSetData() {
		await handleErrors(setModalError, async () => {
			const user = await (providers[newIdentity.provider]).getUser(findUsername);
			setNewIdentity({provider: user.provider_name, user_id: user.provider_user_id, developer_id: -1});
		});
	}

	return AdministratorLayout({
		children:
			<AdminTableEditor
				dataType="Identity"
				partialColumns={columns}
				loadData={identitiesApi.getIdentities.bind(identitiesApi)}
				onAdd={addIdentity}
				onRemove={removeIdentity}
				modalBody={
					<Modal.Body>
						<Stack direction="vertical" gap={4}>
							<Stack direction="horizontal" gap={3}>
								<Stack direction="vertical" gap={1}>
									<div className="input_title">Provider Name (*)</div>
									<Dropdown as={ButtonGroup}
											  className={'dropdown_wide' + (!newIdentity.provider && highlightEmptyMandatoryFields ? ' border-danger' : '')}
											  onSelect={e => setNewIdentity({...newIdentity, provider: e || ''})}>
										<Dropdown.Toggle
											className="dropdown_wide_toggle">{newIdentity.provider}</Dropdown.Toggle>
										<Dropdown.Menu>
											{Object.keys(providers).map((provider) =>
												<Dropdown.Item eventKey={provider}
															   key={provider}>{provider}</Dropdown.Item>
											)}
										</Dropdown.Menu>
									</Dropdown>
								</Stack>
								<Stack direction="vertical" gap={1}>
									<div className="input_title">{newIdentity.provider} User Name</div>
									<Stack direction="horizontal" gap={2}>
										<input maxLength={64}
											   onKeyDown={(event) => event.code === 'Space' && event.preventDefault()}
											   type="text" className={'input_wide'} value={findUsername}
											   onChange={e => setFindUsername(e.target.value)}></input>
										<Button className="content_light_button w-50"
											onClick={findAndSetData}>Find</Button>
									</Stack>
									<div className="input_description ">Maximum length 64 characters, no spaces</div>
								</Stack>
							</Stack>
							<Stack direction="vertical" gap={1}>
								<div className="input_title">User ID (*)</div>
								<input maxLength={64} type="text"
									   className={'input_wide' + (!newIdentity.user_id && highlightEmptyMandatoryFields ? ' border-danger' : '')}
									   value={newIdentity.user_id} onChange={e => setNewIdentity({
										...newIdentity,
										user_id: e.target.value
									})}></input>
								<div className="input_description ">Maximum length 64 characters.</div>
							</Stack>
							<Stack direction="vertical" gap={1}>
								<div className="input_title">Developer (*)</div>
								<Dropdown as={ButtonGroup}
										  className={'dropdown_wide' + (newIdentity.developer_id === -1 && highlightEmptyMandatoryFields ? ' border-danger' : '')}
										  onSelect={e => setNewIdentity({...newIdentity, developer_id: Number(e)})}>
									<Dropdown.Toggle
										className="dropdown_wide_toggle">{getDeveloperNameById(newIdentity.developer_id)}</Dropdown.Toggle>
									<Dropdown.Menu>
										{developers.filter(x => !x.deleted_at).map((grp) =>
											<Dropdown.Item eventKey={grp.id} key={grp.id}>{grp.name}</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown>
							</Stack>
							{modalError ? <Alert key="danger" variant="danger">{modalError}</Alert> : <div></div>}
						</Stack>
					</Modal.Body>
				}/>
	});
}
