import React from 'react';
import {NavLink} from 'react-router-dom';
import {Accordion, Col, Row, Stack} from 'react-bootstrap';
import {Configuration} from '../../sdk';
import RootLayout, {RootLoader} from '../RootLayout';

export async function AdministratorLoader(apiClientConfiguration: Configuration) {
	return await RootLoader(apiClientConfiguration);
}

export default function AdministratorLayout(props: {children?: JSX.Element}) : JSX.Element {
	return RootLayout( {
		children:
			<Row className="page_area">
				<Col className="bg-dark toolbar_area">
					<Stack direction="vertical" gap={1}>
						<Stack direction="vertical" gap={3} className="ps-4 pe-4 pt-4 h-100">
							<Accordion defaultActiveKey="0">
								<Accordion.Item eventKey="0">
									<Accordion.Header>Administration</Accordion.Header>
									<Accordion.Body>
										<Stack direction="vertical" gap={3}>
											<NavLink to={'/administrator/developers'}>Developers</NavLink>
											<NavLink to={'/administrator/identities'}>Identities</NavLink>
											<NavLink to={'/administrator/organizations'}>Organizations</NavLink>
											<NavLink to={'/administrator/entitlements'}>Entitlements</NavLink>
											<NavLink to={'/administrator/entitlement-groups'}>Entitlement Groups</NavLink>
										</Stack>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
							<NavLink to={'/'} className={'navigate pt-5'}>HOME</NavLink>
						</Stack>
					</Stack>
				</Col>
				<Col className="content_area">
					{props.children}
				</Col>
			</Row>
	});
}
