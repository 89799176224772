/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeveloperRequestSchema
 */
export interface DeveloperRequestSchema {
    /**
     * user id from authentication provider
     * @type {string}
     * @memberof DeveloperRequestSchema
     */
    user_id?: string | null;
    /**
     * name of the developer
     * @type {string}
     * @memberof DeveloperRequestSchema
     */
    name: string;
    /**
     * email of the developer
     * @type {string}
     * @memberof DeveloperRequestSchema
     */
    email?: string | null;
    /**
     * is the developer a system admin
     * @type {boolean}
     * @memberof DeveloperRequestSchema
     */
    system_admin?: boolean;
    /**
     * does the developer have personal access
     * @type {boolean}
     * @memberof DeveloperRequestSchema
     */
    personal_access?: boolean;
    /**
     * last organization the developer used
     * @type {number}
     * @memberof DeveloperRequestSchema
     */
    last_used_organization?: number | null;
}

/**
 * Check if a given object implements the DeveloperRequestSchema interface.
 */
export function instanceOfDeveloperRequestSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function DeveloperRequestSchemaFromJSON(json: any): DeveloperRequestSchema {
    return DeveloperRequestSchemaFromJSONTyped(json, false);
}

export function DeveloperRequestSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeveloperRequestSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'name': json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'system_admin': !exists(json, 'system_admin') ? undefined : json['system_admin'],
        'personal_access': !exists(json, 'personal_access') ? undefined : json['personal_access'],
        'last_used_organization': !exists(json, 'last_used_organization') ? undefined : json['last_used_organization'],
    };
}

export function DeveloperRequestSchemaToJSON(value?: DeveloperRequestSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.user_id,
        'name': value.name,
        'email': value.email,
        'system_admin': value.system_admin,
        'personal_access': value.personal_access,
        'last_used_organization': value.last_used_organization,
    };
}

