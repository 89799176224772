/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EntitlementGroupResponseSchema
 */
export interface EntitlementGroupResponseSchema {
    /**
     * ID
     * @type {number}
     * @memberof EntitlementGroupResponseSchema
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof EntitlementGroupResponseSchema
     */
    readonly created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EntitlementGroupResponseSchema
     */
    readonly updated_at?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof EntitlementGroupResponseSchema
     */
    readonly deleted_at?: Date | null;
    /**
     * The identifier for the entitlement group
     * @type {string}
     * @memberof EntitlementGroupResponseSchema
     */
    identifier: string;
    /**
     * A friendly name for the entitlement group
     * @type {string}
     * @memberof EntitlementGroupResponseSchema
     */
    name: string;
}

/**
 * Check if a given object implements the EntitlementGroupResponseSchema interface.
 */
export function instanceOfEntitlementGroupResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "identifier" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function EntitlementGroupResponseSchemaFromJSON(json: any): EntitlementGroupResponseSchema {
    return EntitlementGroupResponseSchemaFromJSONTyped(json, false);
}

export function EntitlementGroupResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntitlementGroupResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updated_at': !exists(json, 'updated_at') ? undefined : (json['updated_at'] === null ? null : new Date(json['updated_at'])),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (json['deleted_at'] === null ? null : new Date(json['deleted_at'])),
        'identifier': json['identifier'],
        'name': json['name'],
    };
}

export function EntitlementGroupResponseSchemaToJSON(value?: EntitlementGroupResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'name': value.name,
    };
}

