import React from 'react';
import {Navigate, useLoaderData, useLocation} from 'react-router-dom';
import {
	ErrorElement,
	getClaimError,
	PortalNavbar,
	useApiClientConfiguration,
	useOrganizationId,
	useUser
} from '../Helper';
import {Configuration, DeveloperApi, OrganizationsApi} from '../sdk';

export async function RootLoader(apiClientConfiguration: Configuration) {
	const developerApi = new DeveloperApi(apiClientConfiguration);
	const developerPromise = developerApi.getCurrentDeveloper().catch(err => {
		console.error(err);
		return developerApi.putCurrentDeveloper();
	});
	const organizationsPromise = (new OrganizationsApi(apiClientConfiguration)).getOrganizations().then(x => x.results).catch(err => {
		console.error(err);
		return [];
	});
	const [developer, organizations] = await Promise.all([developerPromise, organizationsPromise]);
	return {developer, organizations};
}

export default function RootLayout(props: {children?: JSX.Element}) : JSX.Element {
	const {organizations, developer} = useLoaderData() as Awaited<ReturnType<typeof RootLoader>>;
	const user = useUser();
	const location = useLocation();
	const claimError = getClaimError(user);
	const apiClientConfiguration = useApiClientConfiguration();
	const organizationId = useOrganizationId() || developer.last_used_organization || undefined; // useOrganizationId is available when used as root page from organizations

	if(location.pathname === '/' && organizationId) {
		// redirect to default page -> organization page
		return <Navigate to={`/organization/${organizationId}/applications`}/>;
	}
	if(developer.last_used_organization !== organizationId) {
		// save last used organization if different
		const developerApi = new DeveloperApi(apiClientConfiguration);
		developerApi.putCurrentDeveloperLastUsedOrganization({LastUsedOrganizationSchema: {last_used_organization: organizationId}}).catch(console.error);
	}

	return (
		<div>
			<PortalNavbar organizations={organizations} organizationId={organizationId}/> {/* Will show select organization popup */}
			{claimError && <ErrorElement error={claimError} />}
			{organizations.length === 0 && <ErrorElement error={`No organizations available, please make sure your user belongs to a valid organization`} />}
			{organizationId && !organizations.some(x => x.id === organizationId) && <ErrorElement error={'You don\'t have access to this organization, please select another organization from the list'} />}
			{props.children}
		</div>
	);
}
