/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationResponseSchemaAllOf
 */
export interface ApplicationResponseSchemaAllOf {
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponseSchemaAllOf
     */
    readonly organization_id?: number;
    /**
     * id used for authentication
     * @type {string}
     * @memberof ApplicationResponseSchemaAllOf
     */
    readonly client_id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponseSchemaAllOf
     */
    readonly last_used_at?: Date | null;
}

/**
 * Check if a given object implements the ApplicationResponseSchemaAllOf interface.
 */
export function instanceOfApplicationResponseSchemaAllOf(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplicationResponseSchemaAllOfFromJSON(json: any): ApplicationResponseSchemaAllOf {
    return ApplicationResponseSchemaAllOfFromJSONTyped(json, false);
}

export function ApplicationResponseSchemaAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationResponseSchemaAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization_id': !exists(json, 'organization_id') ? undefined : json['organization_id'],
        'client_id': !exists(json, 'client_id') ? undefined : json['client_id'],
        'last_used_at': !exists(json, 'last_used_at') ? undefined : (json['last_used_at'] === null ? null : new Date(json['last_used_at'])),
    };
}

export function ApplicationResponseSchemaAllOfToJSON(value?: ApplicationResponseSchemaAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

