/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Follows the application/problem+json definition (see [RFC 7807](https://tools.ietf.org/html/rfc7807)).
 * @export
 * @interface GetCurrentDeveloper4XXResponse
 */
export interface GetCurrentDeveloper4XXResponse {
    /**
     * An absolute URI reference (see [RFC 3986](https://tools.ietf.org/html/rfc3986)) that identifies the problem type.
     * @type {string}
     * @memberof GetCurrentDeveloper4XXResponse
     */
    type?: string;
    /**
     * A short, human-readable summary of the problem type.
     * @type {string}
     * @memberof GetCurrentDeveloper4XXResponse
     */
    title?: string;
    /**
     * The HTTP status code (see [RFC 7231, Section 6](https://tools.ietf.org/html/rfc7231#section-6)) generated by the origin server for this occurrence of the problem.
     * @type {number}
     * @memberof GetCurrentDeveloper4XXResponse
     */
    status?: number;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof GetCurrentDeveloper4XXResponse
     */
    detail?: string;
    /**
     * A URI reference that identifies the specific occurrence of the problem. It may or may not yield further information if de-referenced.
     * @type {string}
     * @memberof GetCurrentDeveloper4XXResponse
     */
    instance?: string;
}

/**
 * Check if a given object implements the GetCurrentDeveloper4XXResponse interface.
 */
export function instanceOfGetCurrentDeveloper4XXResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetCurrentDeveloper4XXResponseFromJSON(json: any): GetCurrentDeveloper4XXResponse {
    return GetCurrentDeveloper4XXResponseFromJSONTyped(json, false);
}

export function GetCurrentDeveloper4XXResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCurrentDeveloper4XXResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'detail': !exists(json, 'detail') ? undefined : json['detail'],
        'instance': !exists(json, 'instance') ? undefined : json['instance'],
    };
}

export function GetCurrentDeveloper4XXResponseToJSON(value?: GetCurrentDeveloper4XXResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'title': value.title,
        'status': value.status,
        'detail': value.detail,
        'instance': value.instance,
    };
}

