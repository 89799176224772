/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeveloperRequestSchemaAllOf
 */
export interface DeveloperRequestSchemaAllOf {
    /**
     * user id from authentication provider
     * @type {string}
     * @memberof DeveloperRequestSchemaAllOf
     */
    user_id?: string | null;
    /**
     * name of the developer
     * @type {string}
     * @memberof DeveloperRequestSchemaAllOf
     */
    name: string;
    /**
     * email of the developer
     * @type {string}
     * @memberof DeveloperRequestSchemaAllOf
     */
    email?: string | null;
    /**
     * is the developer a system admin
     * @type {boolean}
     * @memberof DeveloperRequestSchemaAllOf
     */
    system_admin?: boolean;
    /**
     * does the developer have personal access
     * @type {boolean}
     * @memberof DeveloperRequestSchemaAllOf
     */
    personal_access?: boolean;
}

/**
 * Check if a given object implements the DeveloperRequestSchemaAllOf interface.
 */
export function instanceOfDeveloperRequestSchemaAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function DeveloperRequestSchemaAllOfFromJSON(json: any): DeveloperRequestSchemaAllOf {
    return DeveloperRequestSchemaAllOfFromJSONTyped(json, false);
}

export function DeveloperRequestSchemaAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeveloperRequestSchemaAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'name': json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'system_admin': !exists(json, 'system_admin') ? undefined : json['system_admin'],
        'personal_access': !exists(json, 'personal_access') ? undefined : json['personal_access'],
    };
}

export function DeveloperRequestSchemaAllOfToJSON(value?: DeveloperRequestSchemaAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.user_id,
        'name': value.name,
        'email': value.email,
        'system_admin': value.system_admin,
        'personal_access': value.personal_access,
    };
}

