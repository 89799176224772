/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetCurrentDeveloper4XXResponse,
  PostCredentialsValidate400Response,
} from '../models';
import {
    GetCurrentDeveloper4XXResponseFromJSON,
    GetCurrentDeveloper4XXResponseToJSON,
    PostCredentialsValidate400ResponseFromJSON,
    PostCredentialsValidate400ResponseToJSON,
} from '../models';

export interface GetAdminRequest {
    provider_name: string;
    provider_user_id: string;
}

/**
 * 
 */
export class AdminsApi extends runtime.BaseAPI {

    /**
     * Checks is an admin for the provider exists
     */
    async getAdminRaw(requestParameters: GetAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.provider_name === null || requestParameters.provider_name === undefined) {
            throw new runtime.RequiredError('provider_name','Required parameter requestParameters.provider_name was null or undefined when calling getAdmin.');
        }

        if (requestParameters.provider_user_id === null || requestParameters.provider_user_id === undefined) {
            throw new runtime.RequiredError('provider_user_id','Required parameter requestParameters.provider_user_id was null or undefined when calling getAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-amz-security-token"] = this.configuration.apiKey("x-amz-security-token"); // IAMAuth authentication
        }

        const response = await this.request({
            path: `/admins/{provider_name}/{provider_user_id}`.replace(`{${"provider_name"}}`, encodeURIComponent(String(requestParameters.provider_name))).replace(`{${"provider_user_id"}}`, encodeURIComponent(String(requestParameters.provider_user_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Checks is an admin for the provider exists
     */
    async getAdmin(requestParameters: GetAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getAdminRaw(requestParameters, initOverrides);
    }

}
