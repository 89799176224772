/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IdentityRequestSchema } from './IdentityRequestSchema';
import {
    IdentityRequestSchemaFromJSON,
    IdentityRequestSchemaFromJSONTyped,
    IdentityRequestSchemaToJSON,
} from './IdentityRequestSchema';

/**
 * 
 * @export
 * @interface DeveloperResponseSchema
 */
export interface DeveloperResponseSchema {
    /**
     * ID
     * @type {number}
     * @memberof DeveloperResponseSchema
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof DeveloperResponseSchema
     */
    readonly created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeveloperResponseSchema
     */
    readonly updated_at?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DeveloperResponseSchema
     */
    readonly deleted_at?: Date | null;
    /**
     * user id from authentication provider
     * @type {string}
     * @memberof DeveloperResponseSchema
     */
    user_id?: string | null;
    /**
     * name of the developer
     * @type {string}
     * @memberof DeveloperResponseSchema
     */
    name: string;
    /**
     * email of the developer
     * @type {string}
     * @memberof DeveloperResponseSchema
     */
    email?: string | null;
    /**
     * is the developer a system admin
     * @type {boolean}
     * @memberof DeveloperResponseSchema
     */
    system_admin?: boolean;
    /**
     * does the developer have personal access
     * @type {boolean}
     * @memberof DeveloperResponseSchema
     */
    personal_access?: boolean;
    /**
     * last organization the developer used
     * @type {number}
     * @memberof DeveloperResponseSchema
     */
    last_used_organization?: number | null;
    /**
     * list of identities that the developer already used to login
     * @type {Array<IdentityRequestSchema>}
     * @memberof DeveloperResponseSchema
     */
    identities?: Array<IdentityRequestSchema>;
}

/**
 * Check if a given object implements the DeveloperResponseSchema interface.
 */
export function instanceOfDeveloperResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function DeveloperResponseSchemaFromJSON(json: any): DeveloperResponseSchema {
    return DeveloperResponseSchemaFromJSONTyped(json, false);
}

export function DeveloperResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeveloperResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updated_at': !exists(json, 'updated_at') ? undefined : (json['updated_at'] === null ? null : new Date(json['updated_at'])),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (json['deleted_at'] === null ? null : new Date(json['deleted_at'])),
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'name': json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'system_admin': !exists(json, 'system_admin') ? undefined : json['system_admin'],
        'personal_access': !exists(json, 'personal_access') ? undefined : json['personal_access'],
        'last_used_organization': !exists(json, 'last_used_organization') ? undefined : json['last_used_organization'],
        'identities': !exists(json, 'identities') ? undefined : ((json['identities'] as Array<any>).map(IdentityRequestSchemaFromJSON)),
    };
}

export function DeveloperResponseSchemaToJSON(value?: DeveloperResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.user_id,
        'name': value.name,
        'email': value.email,
        'system_admin': value.system_admin,
        'personal_access': value.personal_access,
        'last_used_organization': value.last_used_organization,
        'identities': value.identities === undefined ? undefined : ((value.identities as Array<any>).map(IdentityRequestSchemaToJSON)),
    };
}

