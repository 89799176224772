import React, {ReactNode} from 'react';
import {MDXProvider} from '@mdx-js/react';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {oneLight} from 'react-syntax-highlighter/dist/esm/styles/prism';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import OrganizationLayout from './OrganizationLayout';

interface IDocumentationStructure {
	path: string,
	name: string
}
export interface IDocumentationStructureFile extends IDocumentationStructure {
	type: 'file',
	extension: string
}
export interface IDocumentationStructureDirectory extends IDocumentationStructure {
	type: 'directory',
	children?: (IDocumentationStructureDirectory | IDocumentationStructureFile)[]
}

export default function Documentation(props: {document: any}) : JSX.Element {
	function code({className, children, ...props} : {className?: string, children?: ReactNode}) {
		const match = /language-(\w+)/.exec(className || '') // taken from https://mdxjs.com/guides/syntax-highlighting/
		return match && children
			? <div className='syntaxHighlighterParent'>
				<CopyToClipboard text={children.toString()}>
					<button className="copyButton" title="Copy">
						<i className="bi bi-clipboard"/>
					</button>
				</CopyToClipboard>
				<SyntaxHighlighter
					language={match[1]}
					wrapLongLines={true}
					PreTag="div"
					children={String(children).replace(/\n$/, '')}
					{...props}
					style={oneLight} />
			</div>
			: <code className={className} {...props}>{children}</code>
	}

	return OrganizationLayout({
		children:
			<div className="card_container mb-4 documentation">
				<MDXProvider>
					<props.document components={{
						code,
						CodeWithSubstitution: code // Workaround for allowing substitution in codeblocks
					}}/>
				</MDXProvider>
			</div>
	});
}
