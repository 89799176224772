import React from 'react';
import {NavLink, useLoaderData} from 'react-router-dom';
import {Accordion, Col, Row, Stack} from 'react-bootstrap';
import {ErrorElement, isSystemAdmin, useOrganizationId, useUser} from '../Helper';
import {Configuration} from '../sdk';
import RootLayout, {RootLoader} from './RootLayout';
import {IDocumentationStructureDirectory, IDocumentationStructureFile} from './Documentation';
import {capitalCase} from 'capital-case';
import * as docs from '../documentation/structure.json';

export async function OrganizationLoader(apiClientConfiguration: Configuration) {
	return {...await RootLoader(apiClientConfiguration)};
}

export function Menu(props: {organizationId?: number}) {
	const user = useUser();

	function createDocumentationMenus(item: IDocumentationStructureDirectory | IDocumentationStructureFile, organizationId: number): JSX.Element {
		return item.type === 'directory'
			? <Accordion key={`${organizationId}-${item.path}`} >
				<Accordion.Header>{item.name}</Accordion.Header>
				<Accordion.Body>
					<Stack direction="vertical" gap={2}>
						{item.children?.map(x => createDocumentationMenus(x, organizationId))}
					</Stack>
				</Accordion.Body>
			</Accordion>
			: <NavLink key={`${organizationId}-${item.path}`} to={`/organization/${organizationId}/${item.path.replace(item.extension, '')}`}>{capitalCase(item.name.replace(item.extension, ''))}</NavLink>
	}

	return <Stack direction="vertical" gap={3} className="ps-4 pe-4 pt-4 h-100">
		{props.organizationId && <NavLink to={`/organization/${props.organizationId}/applications`}>Applications</NavLink>}
		{props.organizationId && createDocumentationMenus(docs as IDocumentationStructureDirectory, props.organizationId)}
		{isSystemAdmin(user) && <NavLink to={'/administrator/developers'} className={'navigate pt-5'}>Administration</NavLink>}
	</Stack>;
}

export default function OrganizationLayout(props: {children?: JSX.Element}) : JSX.Element {
	const {organizations} = useLoaderData() as Awaited<ReturnType<typeof OrganizationLoader>>;
	const organizationId = useOrganizationId();

	return RootLayout( {
		children: organizations.some(x => x.id === organizationId)
			? <Row className="page_area">
				<Col className="bg-dark toolbar_area">
					<Stack direction="vertical" gap={1}>
						{/*<div className="nav_header_sub text-light font-italic ps-3 pt-1 pb-3">Developer Portal</div>*/}
						<Menu organizationId={organizationId}></Menu>
					</Stack>
				</Col>
				<Col className="content_area">
					{props.children}
				</Col>
			</Row>
			: <ErrorElement error={'Organization is not allowed, please select another organization from the list'} />
	});
}
