/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentityResponseSchema
 */
export interface IdentityResponseSchema {
    /**
     * ID
     * @type {number}
     * @memberof IdentityResponseSchema
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof IdentityResponseSchema
     */
    readonly created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IdentityResponseSchema
     */
    readonly updated_at?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof IdentityResponseSchema
     */
    readonly deleted_at?: Date | null;
    /**
     * Identity provider name
     * @type {string}
     * @memberof IdentityResponseSchema
     */
    provider: string;
    /**
     * Identity provider user id
     * @type {string}
     * @memberof IdentityResponseSchema
     */
    user_id: string;
    /**
     * Developer to whom the identity belongs
     * @type {number}
     * @memberof IdentityResponseSchema
     */
    developer_id: number;
}

/**
 * Check if a given object implements the IdentityResponseSchema interface.
 */
export function instanceOfIdentityResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "provider" in value;
    isInstance = isInstance && "user_id" in value;
    isInstance = isInstance && "developer_id" in value;

    return isInstance;
}

export function IdentityResponseSchemaFromJSON(json: any): IdentityResponseSchema {
    return IdentityResponseSchemaFromJSONTyped(json, false);
}

export function IdentityResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updated_at': !exists(json, 'updated_at') ? undefined : (json['updated_at'] === null ? null : new Date(json['updated_at'])),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (json['deleted_at'] === null ? null : new Date(json['deleted_at'])),
        'provider': json['provider'],
        'user_id': json['user_id'],
        'developer_id': json['developer_id'],
    };
}

export function IdentityResponseSchemaToJSON(value?: IdentityResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': value.provider,
        'user_id': value.user_id,
        'developer_id': value.developer_id,
    };
}

