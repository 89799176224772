import React from 'react';
import {useAuth} from 'react-oidc-context';
import {Button, Col, Container, Image, Modal, Row, Stack} from 'react-bootstrap';
import {LoadingAnimation, NavbarWithErrorElement} from './Helper';

export default function Login() {
	const auth = useAuth();

	if (auth.isLoading) {
		return LoadingAnimation();
	}
	if (auth.error) {
		return <NavbarWithErrorElement error={`Authorization error '${auth.error.message}'`} />;
	}

	// LOGIN page
	return (
		<video autoPlay={true} muted={true} loop={true} className="min-vh-100 min-vw-100 bg-dark" src="/Tile-121234.mp4">
			<Modal show={true} backdrop="static" centered>
				<Modal.Body>
					<Stack className="pt-3 ps-3" direction="horizontal" gap={3} >
						<Image className="align-self-center" roundedCircle={true} style={{width: '42px'}} src="/logo192.png"/>
						<h3>Snke OS</h3>
					</Stack>
					<Container className="p-5">
						<Row>
							<Col>
								<div className="login_title">Sign in to</div>
								<div className="login_title">Developer Portal</div>
								<p className="small">Use one of the following options.</p>
							</Col>
						</Row>
						<Row><Col><br/></Col></Row>
						<Row>
							<Col>
								<Button variant="outline-primary" className="w-100 auth_button" size="lg" onClick={async (event) => {event.currentTarget.disabled = true; await auth.signinRedirect()}}>
									<Stack direction="horizontal" className="justify-content-center" gap={3}>
										<i className="bi bi-github auth_button_icon"/>
										<div>Sign in with GitHub</div>
									</Stack>
								</Button>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button variant="outline-primary" className="w-100 mt-3 auth_button" size="lg" disabled={true}>
									<Stack direction="horizontal" className="justify-content-center" gap={3}>
										<i className="bi bi-microsoft auth_button_icon"/>
										<div>Sign in with Microsoft</div>
									</Stack>
								</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
			</Modal>
		</video>
	);
}
