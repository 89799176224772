/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DeveloperRequestSchema,
  DeveloperResponseSchema,
  GetCurrentDeveloper4XXResponse,
  GetDevelopers200Response,
  GetDevelopers403Response,
  IdSchema,
} from '../models';
import {
    DeveloperRequestSchemaFromJSON,
    DeveloperRequestSchemaToJSON,
    DeveloperResponseSchemaFromJSON,
    DeveloperResponseSchemaToJSON,
    GetCurrentDeveloper4XXResponseFromJSON,
    GetCurrentDeveloper4XXResponseToJSON,
    GetDevelopers200ResponseFromJSON,
    GetDevelopers200ResponseToJSON,
    GetDevelopers403ResponseFromJSON,
    GetDevelopers403ResponseToJSON,
    IdSchemaFromJSON,
    IdSchemaToJSON,
} from '../models';

export interface DeleteDeveloperRequest {
    developer_id: number;
}

export interface GetDeveloperRequest {
    developer_id: number;
}

export interface PostDevelopersRequest {
    DeveloperRequestSchema: DeveloperRequestSchema;
}

export interface PutDeveloperRequest {
    developer_id: number;
    DeveloperRequestSchema: DeveloperRequestSchema;
}

/**
 * 
 */
export class DevelopersApi extends runtime.BaseAPI {

    /**
     * Removes a developer
     */
    async deleteDeveloperRaw(requestParameters: DeleteDeveloperRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.developer_id === null || requestParameters.developer_id === undefined) {
            throw new runtime.RequiredError('developer_id','Required parameter requestParameters.developer_id was null or undefined when calling deleteDeveloper.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/developers/{developer_id}`.replace(`{${"developer_id"}}`, encodeURIComponent(String(requestParameters.developer_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a developer
     */
    async deleteDeveloper(requestParameters: DeleteDeveloperRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDeveloperRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves a developer
     */
    async getDeveloperRaw(requestParameters: GetDeveloperRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeveloperResponseSchema>> {
        if (requestParameters.developer_id === null || requestParameters.developer_id === undefined) {
            throw new runtime.RequiredError('developer_id','Required parameter requestParameters.developer_id was null or undefined when calling getDeveloper.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/developers/{developer_id}`.replace(`{${"developer_id"}}`, encodeURIComponent(String(requestParameters.developer_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeveloperResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Retrieves a developer
     */
    async getDeveloper(requestParameters: GetDeveloperRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeveloperResponseSchema> {
        const response = await this.getDeveloperRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all developers
     */
    async getDevelopersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDevelopers200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/developers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDevelopers200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves all developers
     */
    async getDevelopers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDevelopers200Response> {
        const response = await this.getDevelopersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Creates developer
     */
    async postDevelopersRaw(requestParameters: PostDevelopersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdSchema>> {
        if (requestParameters.DeveloperRequestSchema === null || requestParameters.DeveloperRequestSchema === undefined) {
            throw new runtime.RequiredError('DeveloperRequestSchema','Required parameter requestParameters.DeveloperRequestSchema was null or undefined when calling postDevelopers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/developers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeveloperRequestSchemaToJSON(requestParameters.DeveloperRequestSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdSchemaFromJSON(jsonValue));
    }

    /**
     * Creates developer
     */
    async postDevelopers(requestParameters: PostDevelopersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdSchema> {
        const response = await this.postDevelopersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a developer
     */
    async putDeveloperRaw(requestParameters: PutDeveloperRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.developer_id === null || requestParameters.developer_id === undefined) {
            throw new runtime.RequiredError('developer_id','Required parameter requestParameters.developer_id was null or undefined when calling putDeveloper.');
        }

        if (requestParameters.DeveloperRequestSchema === null || requestParameters.DeveloperRequestSchema === undefined) {
            throw new runtime.RequiredError('DeveloperRequestSchema','Required parameter requestParameters.DeveloperRequestSchema was null or undefined when calling putDeveloper.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/developers/{developer_id}`.replace(`{${"developer_id"}}`, encodeURIComponent(String(requestParameters.developer_id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeveloperRequestSchemaToJSON(requestParameters.DeveloperRequestSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a developer
     */
    async putDeveloper(requestParameters: PutDeveloperRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putDeveloperRaw(requestParameters, initOverrides);
    }

}
