/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationResponseSchema
 */
export interface OrganizationResponseSchema {
    /**
     * ID
     * @type {number}
     * @memberof OrganizationResponseSchema
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationResponseSchema
     */
    readonly created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationResponseSchema
     */
    readonly updated_at?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationResponseSchema
     */
    readonly deleted_at?: Date | null;
    /**
     * external organization identifier
     * @type {string}
     * @memberof OrganizationResponseSchema
     */
    external_id: string;
    /**
     * external organization provider
     * @type {string}
     * @memberof OrganizationResponseSchema
     */
    external_provider: string;
    /**
     * name of the organization
     * @type {string}
     * @memberof OrganizationResponseSchema
     */
    name: string;
    /**
     * description of the organization
     * @type {string}
     * @memberof OrganizationResponseSchema
     */
    description?: string | null;
    /**
     * organization logo url
     * @type {string}
     * @memberof OrganizationResponseSchema
     */
    logo_url?: string;
}

/**
 * Check if a given object implements the OrganizationResponseSchema interface.
 */
export function instanceOfOrganizationResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "external_id" in value;
    isInstance = isInstance && "external_provider" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function OrganizationResponseSchemaFromJSON(json: any): OrganizationResponseSchema {
    return OrganizationResponseSchemaFromJSONTyped(json, false);
}

export function OrganizationResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updated_at': !exists(json, 'updated_at') ? undefined : (json['updated_at'] === null ? null : new Date(json['updated_at'])),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (json['deleted_at'] === null ? null : new Date(json['deleted_at'])),
        'external_id': json['external_id'],
        'external_provider': json['external_provider'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'logo_url': !exists(json, 'logo_url') ? undefined : json['logo_url'],
    };
}

export function OrganizationResponseSchemaToJSON(value?: OrganizationResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_id': value.external_id,
        'external_provider': value.external_provider,
        'name': value.name,
        'description': value.description,
        'logo_url': value.logo_url,
    };
}

