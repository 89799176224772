/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CredentialResponseSchema
 */
export interface CredentialResponseSchema {
    /**
     * 
     * @type {Array<string>}
     * @memberof CredentialResponseSchema
     */
    scopes?: Array<string> | null;
    /**
     * id used for authentication
     * @type {string}
     * @memberof CredentialResponseSchema
     */
    readonly client_id: string;
    /**
     * secret used for authentication
     * @type {string}
     * @memberof CredentialResponseSchema
     */
    readonly client_secret?: string;
}

/**
 * Check if a given object implements the CredentialResponseSchema interface.
 */
export function instanceOfCredentialResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "client_id" in value;

    return isInstance;
}

export function CredentialResponseSchemaFromJSON(json: any): CredentialResponseSchema {
    return CredentialResponseSchemaFromJSONTyped(json, false);
}

export function CredentialResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CredentialResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scopes': !exists(json, 'scopes') ? undefined : json['scopes'],
        'client_id': json['client_id'],
        'client_secret': !exists(json, 'client_secret') ? undefined : json['client_secret'],
    };
}

export function CredentialResponseSchemaToJSON(value?: CredentialResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scopes': value.scopes,
    };
}

