import {Configuration, OrganizationResponseSchema} from './sdk';
import React, {Dispatch, SetStateAction} from 'react';
import {Alert, Button, Col, Dropdown, DropdownButton, Modal, Navbar, Row, Spinner, Stack} from 'react-bootstrap';
import {User} from 'oidc-client-ts';
import assert from 'assert';
import {useAuth} from 'react-oidc-context';
import {
	isRouteErrorResponse,
	useLocation,
	useNavigate,
	useNavigation,
	useParams,
	useRouteError
} from 'react-router-dom';

export function isSystemAdmin(user: User): boolean {
	return user.profile['custom:admin'] === 'true';
}
export function getClaimError(user: User): string | undefined {
	return user.profile['custom:error'] as string || undefined;
}

export function useUser() {
	const auth = useAuth();
	return auth.user!;
}
export function useAuthToken() {
	return useUser().id_token!;
}
export function useApiClientConfiguration() {
	const token = useAuthToken();
	return getApiClientConfiguration(token);
}
export function getApiClientConfiguration(token: string) {
	return new Configuration({
		accessToken: `Bearer ${token}`,
		basePath: process.env.REACT_APP_API_URL,
		headers: {
			'Content-Type': 'application/json;charset=utf-8',
			'Accept': 'application/json;charset=utf-8, application/problem+json;charset=utf-8'
		}
	});
}

export function guard(prop: string | undefined, name: string): string {
	assert(prop, `Missing ${name}`);
	return prop;
}
export function guardEnv(name: string) {
	return guard(process.env[name], name);
}

export async function handleErrors<T>(setError: Dispatch<SetStateAction<string | undefined>>, f:() => Promise<T>): Promise<T> {
	try {
		setError(undefined);
		return await f();
	} catch (e: any) {
		setError(e.message);
		console.error(e);
		throw e;
	}
}
export function useOrganizationId() {
	const {orgId} = useParams();
	return (orgId && +orgId) || undefined;
}
export function useApplicationId() {
	const {appId} = useParams();
	return (appId && +appId) || undefined;
}
export function PortalNavbar(props: {organizations?: OrganizationResponseSchema[], organizationId?: number}) {
	const auth = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const navigation = useNavigation();
	const loading = navigation.state === 'loading';

	const organizationId = props.organizationId;
	const hasOrganizations = Boolean(props.organizations && props.organizations.length > 0);

	async function logoutUser() {
		await auth.removeUser();
		if(process.env.REACT_APP_LOGOUT_URI) { // if idp logout uri is defined, redirect to it
			// navigate(process.env.REACT_APP_LOGOUT_URI); // broken, does relative navigation
			window.location.href = process.env.REACT_APP_LOGOUT_URI;
		}
	}
	function changeOrganization(newOrgId: number) {
		if(organizationId && location.pathname.includes(`/organization/${organizationId}`)) {
			navigate(location.pathname.replace(`/${organizationId}/`, `/${newOrgId}/`));
		} else {
			navigate(`/organization/${newOrgId}/applications`);
		}
	}
	return <div>
		<Navbar bg="dark" variant="dark" fixed="top" className="ps-4 pe-4">
			<Navbar.Brand href="/">SnkeOS Developer Portal</Navbar.Brand>
			<Navbar.Toggle/>
			{auth.isAuthenticated &&
						<Navbar.Collapse className="justify-content-end">
							<Stack direction="horizontal" gap={3}>
								{props.organizations && organizationId &&
									<DropdownButton id="organizations" variant="dark" title={props.organizations.find(x => x.id === organizationId)?.name || ''} onSelect={(eventKey) => eventKey && changeOrganization(+eventKey)}>
										{props.organizations.map((org) => <Dropdown.Item eventKey={org.id} key={org.id}>{org.name}</Dropdown.Item>)}
									</DropdownButton>
								}
								<span className="text-white">{auth.user?.profile.name}</span>
								<Button onClick={logoutUser}>Logout</Button>
							</Stack>
						</Navbar.Collapse>
			}
		</Navbar>
		<Modal show={!loading && !organizationId && hasOrganizations} centered>
			<Modal.Header>
				<Modal.Title>Please select an organization</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Stack direction="vertical" className="me-3 ms-3" gap={3}>
					{props.organizations?.map((org) => <Button variant="outline-primary" key={org.id}
						onClick={() => changeOrganization(org.id)}>{org.name}</Button>)}
				</Stack>
			</Modal.Body>
		</Modal>
	</div>
}

export function ErrorElement(props: {error: string, stack?: string}) {
	console.error(props.error, props.stack);
	return <Row className="page_area">
		<Col className="content_area">
			<div className="d-flex justify-content-center align-items-center alert_text">
				<Stack direction="horizontal" className="justify-content-center" gap={3}>
					<Stack direction="vertical" gap={3}>
						<Alert variant="danger">{props.error}</Alert>
						{props.stack &&
							<div className="pt-2">
								<p className={'text-muted'}>Technical information (stack trace):</p>
								<Alert variant="info">{props.stack}</Alert>
							</div>
						}
					</Stack>
				</Stack>
			</div>
		</Col>
	</Row>
}

export function NavbarWithErrorElement(props: {error: string, stack?: string}) {
	return <div>
		<PortalNavbar />
		{ErrorElement(props)}
	</div>
}
export function RouteErrorElement() {
	const routerError = useRouteError();
	return isRouteErrorResponse(routerError) && routerError.error ? NavbarWithErrorElement({error: routerError.error.message, stack: routerError.error.stack}) : <div></div>;
}

export function LoadingAnimation() {
	return <div className="min-vh-100 d-flex justify-content-center align-items-center">
		<Spinner animation="border" /><h2 className="mx-4">Loading...</h2>
	</div>
}
