/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DatesSchema
 */
export interface DatesSchema {
    /**
     * 
     * @type {Date}
     * @memberof DatesSchema
     */
    readonly created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DatesSchema
     */
    readonly updated_at?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DatesSchema
     */
    readonly deleted_at?: Date | null;
}

/**
 * Check if a given object implements the DatesSchema interface.
 */
export function instanceOfDatesSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DatesSchemaFromJSON(json: any): DatesSchema {
    return DatesSchemaFromJSONTyped(json, false);
}

export function DatesSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatesSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updated_at': !exists(json, 'updated_at') ? undefined : (json['updated_at'] === null ? null : new Date(json['updated_at'])),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (json['deleted_at'] === null ? null : new Date(json['deleted_at'])),
    };
}

export function DatesSchemaToJSON(value?: DatesSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

