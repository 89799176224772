/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EntitlementGroupRequestSchema,
  EntitlementGroupResponseSchema,
  EntitlementIdsSchema,
  EntitlementRequestSchema,
  EntitlementResponseSchema,
  GetCurrentDeveloper4XXResponse,
  GetDevelopers403Response,
  GetEntitlementsGroups200Response,
  GetOrganizationEntitlements200Response,
  IdSchema,
} from '../models';
import {
    EntitlementGroupRequestSchemaFromJSON,
    EntitlementGroupRequestSchemaToJSON,
    EntitlementGroupResponseSchemaFromJSON,
    EntitlementGroupResponseSchemaToJSON,
    EntitlementIdsSchemaFromJSON,
    EntitlementIdsSchemaToJSON,
    EntitlementRequestSchemaFromJSON,
    EntitlementRequestSchemaToJSON,
    EntitlementResponseSchemaFromJSON,
    EntitlementResponseSchemaToJSON,
    GetCurrentDeveloper4XXResponseFromJSON,
    GetCurrentDeveloper4XXResponseToJSON,
    GetDevelopers403ResponseFromJSON,
    GetDevelopers403ResponseToJSON,
    GetEntitlementsGroups200ResponseFromJSON,
    GetEntitlementsGroups200ResponseToJSON,
    GetOrganizationEntitlements200ResponseFromJSON,
    GetOrganizationEntitlements200ResponseToJSON,
    IdSchemaFromJSON,
    IdSchemaToJSON,
} from '../models';

export interface DeleteEntitlementRequest {
    entitlement_id: number;
}

export interface DeleteEntitlementGroupRequest {
    entitlement_group_id: number;
}

export interface GetEntitlementRequest {
    entitlement_id: number;
}

export interface GetEntitlementGroupRequest {
    entitlement_group_id: number;
}

export interface GetOrganizationEntitlementsRequest {
    organization_id: number;
}

export interface PostEntitlementsRequest {
    EntitlementRequestSchema: EntitlementRequestSchema;
}

export interface PostEntitlementsGroupsRequest {
    EntitlementGroupRequestSchema: EntitlementGroupRequestSchema;
}

export interface PutOrganizationEntitlementsRequest {
    organization_id: number;
    EntitlementIdsSchema: EntitlementIdsSchema;
}

/**
 * 
 */
export class EntitlementsApi extends runtime.BaseAPI {

    /**
     * Removes an entitlement
     */
    async deleteEntitlementRaw(requestParameters: DeleteEntitlementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entitlement_id === null || requestParameters.entitlement_id === undefined) {
            throw new runtime.RequiredError('entitlement_id','Required parameter requestParameters.entitlement_id was null or undefined when calling deleteEntitlement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/entitlements/{entitlement_id}`.replace(`{${"entitlement_id"}}`, encodeURIComponent(String(requestParameters.entitlement_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an entitlement
     */
    async deleteEntitlement(requestParameters: DeleteEntitlementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEntitlementRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an entitlement group
     */
    async deleteEntitlementGroupRaw(requestParameters: DeleteEntitlementGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.entitlement_group_id === null || requestParameters.entitlement_group_id === undefined) {
            throw new runtime.RequiredError('entitlement_group_id','Required parameter requestParameters.entitlement_group_id was null or undefined when calling deleteEntitlementGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/entitlements-groups/{entitlement_group_id}`.replace(`{${"entitlement_group_id"}}`, encodeURIComponent(String(requestParameters.entitlement_group_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an entitlement group
     */
    async deleteEntitlementGroup(requestParameters: DeleteEntitlementGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEntitlementGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves an entitlement
     */
    async getEntitlementRaw(requestParameters: GetEntitlementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EntitlementResponseSchema>> {
        if (requestParameters.entitlement_id === null || requestParameters.entitlement_id === undefined) {
            throw new runtime.RequiredError('entitlement_id','Required parameter requestParameters.entitlement_id was null or undefined when calling getEntitlement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/entitlements/{entitlement_id}`.replace(`{${"entitlement_id"}}`, encodeURIComponent(String(requestParameters.entitlement_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntitlementResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Retrieves an entitlement
     */
    async getEntitlement(requestParameters: GetEntitlementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EntitlementResponseSchema> {
        const response = await this.getEntitlementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves an entitlement group
     */
    async getEntitlementGroupRaw(requestParameters: GetEntitlementGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EntitlementGroupResponseSchema>> {
        if (requestParameters.entitlement_group_id === null || requestParameters.entitlement_group_id === undefined) {
            throw new runtime.RequiredError('entitlement_group_id','Required parameter requestParameters.entitlement_group_id was null or undefined when calling getEntitlementGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/entitlements-groups/{entitlement_group_id}`.replace(`{${"entitlement_group_id"}}`, encodeURIComponent(String(requestParameters.entitlement_group_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntitlementGroupResponseSchemaFromJSON(jsonValue));
    }

    /**
     * Retrieves an entitlement group
     */
    async getEntitlementGroup(requestParameters: GetEntitlementGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EntitlementGroupResponseSchema> {
        const response = await this.getEntitlementGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves entitlements
     */
    async getEntitlementsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrganizationEntitlements200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/entitlements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrganizationEntitlements200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves entitlements
     */
    async getEntitlements(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrganizationEntitlements200Response> {
        const response = await this.getEntitlementsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves entitlements groups
     */
    async getEntitlementsGroupsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEntitlementsGroups200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/entitlements-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEntitlementsGroups200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves entitlements groups
     */
    async getEntitlementsGroups(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEntitlementsGroups200Response> {
        const response = await this.getEntitlementsGroupsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all entitlements for the organization
     */
    async getOrganizationEntitlementsRaw(requestParameters: GetOrganizationEntitlementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrganizationEntitlements200Response>> {
        if (requestParameters.organization_id === null || requestParameters.organization_id === undefined) {
            throw new runtime.RequiredError('organization_id','Required parameter requestParameters.organization_id was null or undefined when calling getOrganizationEntitlements.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("UserAuth", ["profile", "openid", "email"]);
        }

        const response = await this.request({
            path: `/organizations/{organization_id}/entitlements`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organization_id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrganizationEntitlements200ResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves all entitlements for the organization
     */
    async getOrganizationEntitlements(requestParameters: GetOrganizationEntitlementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrganizationEntitlements200Response> {
        const response = await this.getOrganizationEntitlementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates entitlement
     */
    async postEntitlementsRaw(requestParameters: PostEntitlementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdSchema>> {
        if (requestParameters.EntitlementRequestSchema === null || requestParameters.EntitlementRequestSchema === undefined) {
            throw new runtime.RequiredError('EntitlementRequestSchema','Required parameter requestParameters.EntitlementRequestSchema was null or undefined when calling postEntitlements.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/entitlements`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntitlementRequestSchemaToJSON(requestParameters.EntitlementRequestSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdSchemaFromJSON(jsonValue));
    }

    /**
     * Creates entitlement
     */
    async postEntitlements(requestParameters: PostEntitlementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdSchema> {
        const response = await this.postEntitlementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates entitlement group
     */
    async postEntitlementsGroupsRaw(requestParameters: PostEntitlementsGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdSchema>> {
        if (requestParameters.EntitlementGroupRequestSchema === null || requestParameters.EntitlementGroupRequestSchema === undefined) {
            throw new runtime.RequiredError('EntitlementGroupRequestSchema','Required parameter requestParameters.EntitlementGroupRequestSchema was null or undefined when calling postEntitlementsGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/entitlements-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntitlementGroupRequestSchemaToJSON(requestParameters.EntitlementGroupRequestSchema),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdSchemaFromJSON(jsonValue));
    }

    /**
     * Creates entitlement group
     */
    async postEntitlementsGroups(requestParameters: PostEntitlementsGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdSchema> {
        const response = await this.postEntitlementsGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Assign entitlements to the organization
     */
    async putOrganizationEntitlementsRaw(requestParameters: PutOrganizationEntitlementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organization_id === null || requestParameters.organization_id === undefined) {
            throw new runtime.RequiredError('organization_id','Required parameter requestParameters.organization_id was null or undefined when calling putOrganizationEntitlements.');
        }

        if (requestParameters.EntitlementIdsSchema === null || requestParameters.EntitlementIdsSchema === undefined) {
            throw new runtime.RequiredError('EntitlementIdsSchema','Required parameter requestParameters.EntitlementIdsSchema was null or undefined when calling putOrganizationEntitlements.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("AdminAuth", []);
        }

        const response = await this.request({
            path: `/organizations/{organization_id}/entitlements`.replace(`{${"organization_id"}}`, encodeURIComponent(String(requestParameters.organization_id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EntitlementIdsSchemaToJSON(requestParameters.EntitlementIdsSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign entitlements to the organization
     */
    async putOrganizationEntitlements(requestParameters: PutOrganizationEntitlementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putOrganizationEntitlementsRaw(requestParameters, initOverrides);
    }

}
