import React, {useState} from 'react';
import {Alert, Button, ButtonGroup, Dropdown, Form, Modal, Spinner, Stack} from 'react-bootstrap';
import {handleErrors, useApiClientConfiguration} from '../../Helper';
import {providers} from '../../providers';
import assert from 'assert';
import {
	Configuration,
	DeleteOrganizationRequest,
	EntitlementResponseSchema,
	EntitlementsApi,
	OrganizationRequestSchema,
	OrganizationResponseSchema,
	OrganizationsApi
} from '../../sdk';
import {TypeOnSelectionChangeArg} from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import {TypeColumn} from '@inovua/reactdatagrid-community/types';
import AdminTableEditor from './AdminTableEditor';
import {EntitlementsLoader} from './Entitlements';
import {useLoaderData} from 'react-router-dom';
import AdministratorLayout from './AdministratorLayout';

export async function OrganizationsLoader(apiClientConfiguration: Configuration) {
	return await EntitlementsLoader(apiClientConfiguration);
}

export default function Organizations() : JSX.Element {
	const apiClientConfiguration = useApiClientConfiguration();
	const organizationsApi = new OrganizationsApi(apiClientConfiguration);
	const entitlementsApi = new EntitlementsApi(apiClientConfiguration);
	const data = useLoaderData() as Awaited<ReturnType<typeof OrganizationsLoader>>;

	const [selectedOrganization, setSelectedOrganization] = useState<OrganizationResponseSchema | undefined>();
	const [entitlements, setEntitlements] = useState<Array<EntitlementResponseSchema & {group_identifier: string, checked: boolean}>>([]);
	const [matchedEntitlements, setMatchedEntitlements] = useState(false);
	const [modalError, setModalError] = useState<string | undefined>();
	const [highlightEmptyMandatoryFields, setHighlightEmptyMandatoryFields] = useState(false);

	// List of providers
	const [newOrganization, setNewOrganization] = useState<OrganizationRequestSchema>(getOrganizationInitialState());

	const columns: TypeColumn[] = [
		{name: 'name', header: 'Name', minWidth: 20, defaultFlex: 1},
		{name: 'description', header: 'Description', minWidth: 20, defaultFlex: 3},
		{name: 'logo_url', header: 'Logo', minWidth: 20, defaultFlex: 3},
		{name: 'external_id', header: 'Provider ID', type: 'number', minWidth: 20, defaultFlex: 1.2},
		{name: 'external_provider', header: 'Provider', minWidth: 20, defaultFlex: 1},
	];

	function getOrganizationInitialState(): OrganizationRequestSchema {
		return {external_provider: Object.keys(providers)[0], external_id: '', name: ''};
	}

	async function removeOrganization(organization: DeleteOrganizationRequest) {
		await organizationsApi.deleteOrganization(organization);
	}

	const onSelectionChange = async (row: TypeOnSelectionChangeArg) => {
		if(!row.selected) return;
		setMatchedEntitlements(false);
		const organization = row.data as OrganizationResponseSchema;
		setSelectedOrganization(organization);
		const orgEntitlements = await entitlementsApi.getOrganizationEntitlements({organization_id: organization.id})
		const checkedIds = new Set(orgEntitlements.results.map(e => e.id));
		setEntitlements(
			data.entitlements
				.filter(x => !x.deleted_at)
				.sort((x, y) => x.id - y.id)
				.sort((x, y) => x.group_id - y.group_id)
				.map(x => ({...x, group_identifier: data.entitlementGroups.find(y => y.id === x.group_id)!.identifier, checked: checkedIds.has(x.id)}))
		);
		setMatchedEntitlements(true);
	};

	async function addOrganization(organizations: Array<OrganizationResponseSchema>) {
		await handleErrors(setModalError, async () => {
			setHighlightEmptyMandatoryFields(true);
			assert.ok(newOrganization.external_id && newOrganization.external_provider && newOrganization.name, 'Please fill in all mandatory fields');
			assert.ok(!organizations.some(d => !d.deleted_at && d.external_id === newOrganization.external_id && d.external_provider === newOrganization.external_provider), 'Organization for provider and id already exists');
			await organizationsApi.postOrganizations({OrganizationRequestSchema: newOrganization});
			setNewOrganization(getOrganizationInitialState());
			setHighlightEmptyMandatoryFields(false);
		});
	}
	async function saveOrganizationEntitlements() {
		if (selectedOrganization) {
			const selectedEntitlements = entitlements.filter(x => x.checked).map(x => x.id);
			await entitlementsApi.putOrganizationEntitlements({
				organization_id: selectedOrganization.id,
				EntitlementIdsSchema: {entitlement_ids: selectedEntitlements}
			});
		}
	}

	async function findAndSetData() {
		await handleErrors(setModalError, async () => {
			setNewOrganization(await providers[newOrganization.external_provider].getOrganization(newOrganization.name));
		});
	}

	return AdministratorLayout({
		children:
			<Stack direction="vertical" gap={4}>
				<AdminTableEditor
					dataType="Organization"
					partialColumns={columns}
					loadData={organizationsApi.getOrganizations.bind(organizationsApi)}
					onAdd={addOrganization}
					onRemove={(x) => removeOrganization({organization_id: x.id})}
					onSelectionChange={onSelectionChange}
					modalBody={
						<Modal.Body>
							<Stack direction="vertical" gap={4}>
								<Stack direction="horizontal" gap={3}>
									<Stack direction="vertical" gap={1}>
										<div className="input_title">Provider Name (*)</div>
										<Dropdown as={ButtonGroup}
												  className={'dropdown_wide' + (!newOrganization.external_provider && highlightEmptyMandatoryFields ? ' border-danger' : '')}
												  onSelect={e => setNewOrganization({
													  ...newOrganization,
													  external_provider: e || ''
												  })}>
											<Dropdown.Toggle
												className="dropdown_wide_toggle">{newOrganization.external_provider}</Dropdown.Toggle>
											<Dropdown.Menu>
												{Object.keys(providers).map((provider) =>
													<Dropdown.Item eventKey={provider}
																   key={provider}>{provider}</Dropdown.Item>
												)}
											</Dropdown.Menu>
										</Dropdown>
									</Stack>
									<Stack direction="vertical" gap={1}>
										<div className="input_title">{newOrganization.external_provider} Organization
											Name (*)
										</div>
										<Stack direction="horizontal" gap={2}>
											<input maxLength={64}
												   onKeyDown={(event) => event.code === 'Space' && event.preventDefault()}
												   type="text"
												   className={'input_wide' + (!newOrganization.external_id && highlightEmptyMandatoryFields ? ' border-danger' : '')}
												   value={newOrganization.name} onChange={e => setNewOrganization({
													...newOrganization,
													name: e.target.value
												})}></input>
											<Button className="content_light_button w-50"
												onClick={findAndSetData}>Find</Button>
										</Stack>
										<div className="input_description ">Maximum length 64 characters, no spaces
										</div>
									</Stack>
								</Stack>
								<Stack direction="vertical" gap={1}>
									<div className="input_title">Provider ID (*)</div>
									<input maxLength={64} type="text"
										   className={'input_wide' + (!newOrganization.external_id && highlightEmptyMandatoryFields ? ' border-danger' : '')}
										   value={newOrganization.external_id} onChange={e => setNewOrganization({
											...newOrganization,
											external_id: e.target.value
										})}></input>
									<div className="input_description ">Maximum length 64 characters.</div>
								</Stack>
								<Stack direction="vertical" gap={1}>
									<div className="input_title">Description</div>
									<input type="text" value={newOrganization.description?.toString()}
										   className='input_wide' onChange={e => setNewOrganization({
											...newOrganization,
											description: e.target.value
										})}></input>
								</Stack>
								<Stack direction="vertical" gap={1}>
									<div className="input_title">Logo Url</div>
									<input type="text" value={newOrganization.logo_url?.toString()}
										   className='input_wide' onChange={e => setNewOrganization({
											...newOrganization,
											logo_url: e.target.value
										})}></input>
								</Stack>
								{modalError ? <Alert key="danger" variant="danger">{modalError}</Alert> : <div></div>}
							</Stack>
						</Modal.Body>
					}/>

				{selectedOrganization && entitlements.length > 0 ?
					<div>
						<div>
							<span className="fw-bold">Organization: </span>
							<span className='fw-bold'>{selectedOrganization.name}</span>
							{!matchedEntitlements && <Spinner animation="border" size="sm" className="ms-2"></Spinner>}
						</div>
						<Stack direction="horizontal" className="align-items-md-start" gap={5}>
							{entitlements.map(entitlement =>
								<Form.Check
									key={`${entitlement.group_identifier}/${entitlement.name}`}
									inline
									id={entitlement.id.toString()}
									label={`${entitlement.group_identifier}/${entitlement.name}`}
									onChange={(e) => {
										entitlement.checked = e.target.checked;
										setEntitlements([...entitlements]);
									}}
									checked={entitlement.checked}
									name='entGroup'
									type='checkbox'
									disabled={!!selectedOrganization.deleted_at || !matchedEntitlements || entitlement.group_identifier === 'org'}
								/>
							)}
						</Stack>
						<Button className="mt-3 content_dark_button"
							disabled={!!selectedOrganization.deleted_at || !matchedEntitlements}
							onClick={saveOrganizationEntitlements}>Save</Button>
					</div>
					: <div></div>}
			</Stack>
	});
}
