import './styles/main.scss';
import React, {ReactElement, useEffect, useState} from 'react';
import {createRoot} from 'react-dom/client';
import {AuthProvider, useAuth} from 'react-oidc-context';
import {
	getApiClientConfiguration,
	guardEnv,
	isSystemAdmin,
	LoadingAnimation,
	NavbarWithErrorElement,
	RouteErrorElement
} from './Helper';
import {createBrowserRouter, createRoutesFromElements, json, Route, RouterProvider} from 'react-router-dom';
import Login from './Login';
import RootLayout, {RootLoader} from './views/RootLayout';
import Application, {ApplicationLoader} from './views/Application';
import Applications, {ApplicationsLoader} from './views/Applications';
import AddOrUpdateApplication from './views/AddOrUpdateApplication';
import Organizations, {OrganizationsLoader} from './views/admin/Organizations';
import Developers from './views/admin/Developers';
import Identities, {IdentitiesLoader} from './views/admin/Identities';
import Entitlements, {EntitlementsLoader} from './views/admin/Entitlements';
import EntitlementGroups, {EntitlementGroupsLoader} from './views/admin/EntitlementGroups';
import {OrganizationLoader} from './views/OrganizationLayout';
import {Configuration} from './sdk';
import Documentation, {IDocumentationStructureDirectory, IDocumentationStructureFile} from './views/Documentation';
import * as docs from './documentation/structure.json';
import {AdministratorLoader} from './views/admin/AdministratorLayout';

const oidcConfig = {
	authority: guardEnv('REACT_APP_AUTHORITY'),
	client_id: guardEnv('REACT_APP_CLIENT_ID'),
	redirect_uri: guardEnv('REACT_APP_REDIRECT_URI'),
	scope: 'openid profile email',
	response_type: 'code',
	onSigninCallback: (): void => {
		window.history.replaceState({}, document.title, window.location.pathname);
	}
};
// required to call APIs, make sure its defined!
guardEnv('REACT_APP_API_URL');

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<AuthProvider {...oidcConfig}>
			<App />
		</AuthProvider>
	</React.StrictMode>
);

async function createDocumentationRoutes(item: IDocumentationStructureDirectory | IDocumentationStructureFile, configuration: Configuration): Promise<ReactElement[]> {
	if(item.type === 'directory') {
		return item.children ? (await Promise.all(item.children.map(x => createDocumentationRoutes(x, configuration)))).flat() : []
	}
	return [<Route key={`route-${item.path}`} path={`/organization/:orgId/${item.path.replace(item.extension, '')}`} element={<Documentation document={(await import(`./${item.path}`)).default}/>} loader={async () => json(await OrganizationLoader(configuration), {status: 200})}/>]
}

function App() {
	const auth = useAuth();
	const [configuration, setConfiguration] = useState<Configuration | undefined>();
	const [documentationRoutes, setDocumentationRoutes] = useState<ReactElement[]>();

	// starting page, still not in router, so we can't use loader
	useEffect(() => {
		if(auth.isAuthenticated && auth.user) {
			const config = getApiClientConfiguration(auth.user.id_token!);
			setConfiguration(config);
			createDocumentationRoutes(docs as IDocumentationStructureDirectory, config).then(setDocumentationRoutes);
		}
	}, [auth]);


	if(!auth.isAuthenticated || !auth.user) {
		return <Login/>;
	}
	if(!configuration || !documentationRoutes) {
		return LoadingAnimation();
	}

	return <RouterProvider router={
		createBrowserRouter(
			createRoutesFromElements(
				<Route errorElement={<RouteErrorElement />}>
					<Route path="/" element={<RootLayout />} loader={async () => json(await RootLoader(configuration), {status: 200})}/>
					<Route path="/organization/:orgId/applications" element={<Applications/>} loader={async ({params}) => json(await ApplicationsLoader(configuration, params.orgId!), {status: 200})}/>
					<Route path="/organization/:orgId/applications/add" element={<AddOrUpdateApplication/>} loader={async () => json(await OrganizationLoader(configuration), {status: 200})}/>
					<Route path="/organization/:orgId/applications/:appId" element={<Application/>} loader={async ({params}) => json(await ApplicationLoader(configuration, params.orgId!, params.appId!), {status: 200})}/>
					<Route path="/organization/:orgId/applications/:appId/update" element={<AddOrUpdateApplication/>} loader={async ({params}) => json(await ApplicationLoader(configuration, params.orgId!, params.appId!), {status: 200})}/>
					{
						// Route should be all at the same level (not contained in own tree like "<Route>"), otherwise it'll reload the ui and menus will lost collapsed state
						documentationRoutes.flat()
					}
					{ isSystemAdmin(auth.user) &&
						[
							<Route path="/administrator/developers" element={<Developers/>} loader={async () => json(await AdministratorLoader(configuration), {status: 200})}/>,
							<Route path="/administrator/identities" element={<Identities/>} loader={async () => json(await IdentitiesLoader(configuration), {status: 200})}/>,
							<Route path="/administrator/organizations" element={<Organizations/>} loader={async () => json(await OrganizationsLoader(configuration), {status: 200})}/>,
							<Route path="/administrator/entitlements" element={<Entitlements/>} loader={async () => json(await EntitlementsLoader(configuration), {status: 200})}/>,
							<Route path="/administrator/entitlement-groups" element={<EntitlementGroups/>} loader={async () => json(await EntitlementGroupsLoader(configuration), {status: 200})}/>
						]
					}
					<Route path="*" element={<NavbarWithErrorElement error={'Page not found!'} />}/>
				</Route>
			)
		)
	}></RouterProvider>;
}
