import React, {useState} from 'react';
import {Alert, ButtonGroup, Dropdown, Modal, Stack} from 'react-bootstrap';
import {handleErrors, useApiClientConfiguration} from '../../Helper';
import {Configuration, DeleteEntitlementRequest, EntitlementRequestSchema, EntitlementsApi} from '../../sdk';
import assert from 'assert';
import {TypeColumn} from '@inovua/reactdatagrid-community/types';
import AdminTableEditor from './AdminTableEditor';
import {useLoaderData} from 'react-router-dom';
import {EntitlementGroupsLoader} from './EntitlementGroups';
import AdministratorLayout from './AdministratorLayout';

export async function EntitlementsLoader(apiClientConfiguration: Configuration) {
	const entitlementsPromise = (new EntitlementsApi(apiClientConfiguration)).getEntitlements().then(x => x.results);
	return {...await EntitlementGroupsLoader(apiClientConfiguration), entitlements: await entitlementsPromise};
}

export default function Entitlements() : JSX.Element {
	const apiClientConfiguration = useApiClientConfiguration();
	const entitlementsApi = new EntitlementsApi(apiClientConfiguration);
	const {entitlementGroups} = useLoaderData() as Awaited<ReturnType<typeof EntitlementGroupsLoader>>;

	const [modalError, setModalError] = useState<string | undefined>();
	const [highlightEmptyMandatoryFields, setHighlightEmptyMandatoryFields] = useState(false);
	const [newEntitlement, setNewEntitlement] = useState<EntitlementRequestSchema>(getEntitlementInitialState());

	const entColumns: TypeColumn[] = [
		{name: 'name', header: 'Name', minWidth: 20, defaultFlex: 1, editable: false},
		{name: 'description', header: 'Description', minWidth: 20, defaultFlex: 2, editable: false},
		{name: 'group_id', header: 'Group', minWidth: 20, defaultFlex: 1, editable: false, render: (x: { value: any }) => getGroupNameFromId(x.value)},
	]

	function getEntitlementInitialState(): EntitlementRequestSchema {
		return {group_id: -1, name: '', description: ''};
	}

	async function removeEntitlements(entitlement: DeleteEntitlementRequest) {
		await entitlementsApi.deleteEntitlement(entitlement);
	}
	function getGroupNameFromId(id: any) : string {
		return (id && entitlementGroups.find(y => y.id === id)?.name) || '-';
	}

	async function addEntitlement() {
		await handleErrors(setModalError, async () => {
			setHighlightEmptyMandatoryFields(true);
			assert.ok(newEntitlement.group_id && newEntitlement.name && newEntitlement.description, 'Please fill in all mandatory fields');
			await entitlementsApi.postEntitlements({EntitlementRequestSchema: newEntitlement});
			setNewEntitlement(getEntitlementInitialState());
			setHighlightEmptyMandatoryFields(false);
		});
	}

	return AdministratorLayout({
		children:
			<AdminTableEditor
				dataType="Entitlement"
				partialColumns={entColumns}
				loadData={entitlementsApi.getEntitlements.bind(entitlementsApi)}
				onAdd={addEntitlement}
				onRemove={(x) => removeEntitlements({entitlement_id: x.id})}
				modalBody={
					<Modal.Body>
						<Stack direction="vertical" gap={4}>
							<Stack direction="vertical" gap={1}>
								<div className="input_title">Name (*)</div>
								<input onKeyDown={(event) => event.code === 'Space' && event.preventDefault()}
									   maxLength={64} type="text"
									   className={'input_wide' + (!newEntitlement.name && highlightEmptyMandatoryFields ? ' border-danger' : '')}
									   value={newEntitlement.name}
									   onChange={e => setNewEntitlement({
										   ...newEntitlement,
										   name: e.target.value
									   })}></input>
								<div className="input_description ">Maximum length 64 characters, no spaces</div>
							</Stack>
							<Stack direction="vertical" gap={1}>
								<div className="input_title">Description (*)</div>
								<input type="text"
									   className={'input_wide' + (!newEntitlement.description && highlightEmptyMandatoryFields ? ' border-danger' : '')}
									   value={newEntitlement.description} onChange={e => setNewEntitlement({
										...newEntitlement,
										description: e.target.value
									})}></input>
							</Stack>
							<Stack direction="vertical" gap={1}>
								<div className="input_title">Group (*)</div>
								<Dropdown as={ButtonGroup}
										  className={'dropdown_wide' + (newEntitlement.group_id === -1 && highlightEmptyMandatoryFields ? ' border-danger' : '')}
										  onSelect={e => setNewEntitlement({...newEntitlement, group_id: Number(e)})}>
									<Dropdown.Toggle
										className="dropdown_wide_toggle">{getGroupNameFromId(newEntitlement.group_id)}</Dropdown.Toggle>
									<Dropdown.Menu>
										{entitlementGroups.filter(x => !x.deleted_at).map((grp) =>
											<Dropdown.Item eventKey={grp.id} key={grp.id}>{grp.name}</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown>
							</Stack>
							{modalError ? <Alert key="danger" variant="danger">{modalError}</Alert> : <div></div>}
						</Stack>
					</Modal.Body>
				}/>
	});
}
