/* tslint:disable */
/* eslint-disable */
/**
 * Cloud APM Dev Portal
 * APIs for Cloud APM Dev Portal
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EntitlementResponseSchema
 */
export interface EntitlementResponseSchema {
    /**
     * ID
     * @type {number}
     * @memberof EntitlementResponseSchema
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof EntitlementResponseSchema
     */
    readonly created_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EntitlementResponseSchema
     */
    readonly updated_at?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof EntitlementResponseSchema
     */
    readonly deleted_at?: Date | null;
    /**
     * name of the entitlement
     * @type {string}
     * @memberof EntitlementResponseSchema
     */
    name: string;
    /**
     * description of the entitlement
     * @type {string}
     * @memberof EntitlementResponseSchema
     */
    description: string;
    /**
     * group of the entitlement
     * @type {number}
     * @memberof EntitlementResponseSchema
     */
    group_id: number;
}

/**
 * Check if a given object implements the EntitlementResponseSchema interface.
 */
export function instanceOfEntitlementResponseSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "group_id" in value;

    return isInstance;
}

export function EntitlementResponseSchemaFromJSON(json: any): EntitlementResponseSchema {
    return EntitlementResponseSchemaFromJSONTyped(json, false);
}

export function EntitlementResponseSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntitlementResponseSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created_at': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'updated_at': !exists(json, 'updated_at') ? undefined : (json['updated_at'] === null ? null : new Date(json['updated_at'])),
        'deleted_at': !exists(json, 'deleted_at') ? undefined : (json['deleted_at'] === null ? null : new Date(json['deleted_at'])),
        'name': json['name'],
        'description': json['description'],
        'group_id': json['group_id'],
    };
}

export function EntitlementResponseSchemaToJSON(value?: EntitlementResponseSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'group_id': value.group_id,
    };
}

